<template>
  <div class="cardloading-list-item">
    <div class="cardloadingitem__inner" ref="domCardInner">
      <div class="skeleton-block">
        <div class="skeleton-row">
            <VueSkeletonLoader
              type="rect"
              :width="100"
              :height="100"
              
            />
            <div class="skeleton-block">
              <VueSkeletonLoader
                type="rect"
                :width="200"
                :height="24"
              />
              <VueSkeletonLoader
                type="rect"
                :width="240"
                :height="24"
              />
              <div class="skeleton-row">
                <VueSkeletonLoader
                  type="rect"
                  :width="80"
                  :height="16"
                />
                <VueSkeletonLoader
                  type="rect"
                  :width="80"
                  :height="16"
                />
                </div>            
            </div>
        </div>
      </div>
      <div class="skeleton-separator">
        <VueSkeletonLoader
          type="rect"
          :width="320"
          :height="2"
        />
      </div>
      <div class="skeleton-block">
        <div class="skeleton-row">
            <VueSkeletonLoader
              type="rect"
              :width="100"
              :height="100"
            />
            <div class="skeleton-block">
            <VueSkeletonLoader
              type="rect"
              :width="200"
              :height="24"
            />
            <VueSkeletonLoader
              type="rect"
              :width="240"
              :height="24"
            />
            <div class="skeleton-row">
            <VueSkeletonLoader
              type="rect"
              :width="80"
              :height="16"
            />
            <VueSkeletonLoader
              type="rect"
              :width="80"
              :height="16"
            />
            </div>
            </div>
        </div>
      </div>
      <div class="skeleton-separator">
        <VueSkeletonLoader
          type="rect"
          :width="320"
          :height="2"
        />
      </div>
      <div class="skeleton-block">
        <div class="skeleton-row">
            <VueSkeletonLoader
              type="rect"
              :width="100"
              :height="100"
              
            />
            <div class="skeleton-block">
            <VueSkeletonLoader
              type="rect"
              :width="200"
              :height="24"
              
            />
            <VueSkeletonLoader
              type="rect"
              :width="240"
              :height="24"
              
            />
            <div class="skeleton-row">
            <VueSkeletonLoader
              type="rect"
              :width="80"
              :height="16"
            />
            <VueSkeletonLoader
              type="rect"
              :width="80"
              :height="16"
            />
            </div>            
            </div>
        </div>
      </div>
      <div class="skeleton-block skeleton-block--bottom">
            <VueSkeletonLoader
              type="rect"
              :width="240"
              :height="32"
            />
      </div>      
    </div>
  </div>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  name: "CardSkeleton",
  components: {
    VueSkeletonLoader
  },
  data() {
    return {
    }
  },
  computed: {
    width100() {
      return (this.$refs && this.$refs.domCardInner ? this.$refs.domCardInner.clientWidth : "300");
    }
  }
};
</script>
