
<template>
  <div class="form-property" @transitionend="onTransitionend" :class="{ 'is-folding': folding, 'is-disabled': disabled, 'has-error': item.hasError }" :style="style" >
    <div class="property-inner" ref="domInner">
      <p class="form-property__label">{{ item.label }} <Quickinfo v-if="item.quickinfo" :item="item"/></p>
      <p class="form-error" v-show="item.hasError" v-html="errorMsg"></p>
      <div class="radio-group" :class="'radio-group--' +  direction + (disabled?' is-disabled':'')">
        <div class="radio-group__item"
          v-for="option in item.options"
          :key="'option_' + option.uid"
          :class="{ 'is-selected': (option.value == value || option.value == storedValue.value), 'is-hidden': !option.show }"
        >
          <input 
            type="radio" 
            v-model="value" 
            :name="item.name + '_' + option.value"
            :id="item.name + '_' + option.value"
            :value="option.value"
            @change="onChange($event, item)"
            :disabled="disabled"
            :class="{ 'is-selected': (option.value == value || option.value == storedValue.value) }"
          />
          <label :for="item.name + '_' + option.value" >{{ option.text }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ContentController } from "@/controller";
import { EventBus } from "@/bus/eventbus.js";
import { Quickinfo } from "@/components/";

export default {
  name: "RadioGroup",
  components: {
    Quickinfo
  },
  computed: {
    direction() {
      let str = this.item.type.substr(this.item.type.indexOf('_')+1);
      return str;
    },
    style() {
      const height = this.foldingHeight == "auto" ? "auto" : this.foldingHeight + "px;";
      return "height: " + height;
      //return "height: auto;";
    }

  },
  props: {
      handles: {
          type: Array,
          default: null
      },
      item: {
          type: Object,
          default: null
      },
      storedValue: {
        type: Object,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      },
  },
  data() {
    return {
      value: "",
      folding: false,
      foldingHeight: "auto",
      errorMsg: "Fehler bei der Eingabe"
    }
  },
  created() {
    /* if (this.item && this.item.show == false && this.item.isConditional) {
      this.folding = true;
      this.foldingHeight = 0;
    } */
    this.value = this.storedValue.value;
    EventBus.$on("onFormError", () => this.checkError());
    this.$emit("onCreated", this.item.uid);
  },
  mounted() {
  
    /* if (this.item && this.item.show == false && this.item.isConditional) {
      this.foldingHeight = 0;
    } */
      //TODO not desired
    /* if (this.item && this.item.mandatory && !this.value.value && !this.storedValue.value) {
      this.error = true;
    } */
  },
  destroy() {
    console.log("RadioGroup:destroy");
  },
  watch: {
    storedValue: {
      handler: function() {
        this.value = this.storedValue.value;
      }
    }
  },
  methods: {
    onChange(ev, item) {
      this.checkError();
      this.$emit("onChange", ev, item, this.value);
    },
    onTransitionend() {
      /* this.folding = false;
      this.foldingHeight = "auto"; */
    },
    checkError() {
        //TODO: check this - false even values are given
      //console.log("RadioGroup:checkError:value", this.item.name, this.item.show, this.item.mandatory, this.value, this.item.show && this.item.mandatory && !this.value);
      if (this.item.show && this.item.mandatory && !this.value) {
        this.item.hasError = true;
        this.errorMsg = ContentController.getContent("ERROR_MSG_MANDATORY");
      } else {
        this.item.hasError = false;
      }
      this.$emit("onErrorChange", this.item.name, this.item.hasError);
    },
    /* getOptionsToShow(item) {
      return ConfiguratorFormController.getOptionsToShow(item);
    }, */
  },
};
</script>