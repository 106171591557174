<template>
  <div class="form-property" :class="{ 'has-error': error, 'is-disabled': disabled }">
    <label :for="'input'+item.name">{{ item.label }} <Quickinfo v-if="item.quickinfo" :item="item"/></label>
    <textarea 
      :type="item.type" 
      :id="'textarea'+item.name" 
      v-model="value" 
      :name="item.name" 
      @change="onChange($event, item)" 
      :class="{'has-placeholder': item.unit != '', 'is-disabled': disabled}"
      :step="(item.step?item.step:null)"
      :disabled="disabled"
    />  
  </div>
</template>

<script>
import { Quickinfo } from "@/components/";

export default {
  name: "TextField",
  components: {
    Quickinfo
  },
  computed: {
  },
  props: {
      handles: {
        type: Array,
        default: null
      },
      item: {
        type: Object,
        default: null
      },
      storedValue: {
        type: Object,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      }
  },
  data() {
    return {
      value: 0,
      error: false
    }
  },
  watch: {
    storedValue(val) {
      //console.log('input watch storedValue', val.value);
      this.value = val.value;
    }
  },
  created() {
    this.value = this.storedValue.value;
    //console.log('input created', this.value);
  },
  methods: {
    onChange(ev, item) {
      this.$emit("onChange", ev, item, this.value);
    }
  },
};
</script>