<template>
  <div class="page page-co2emission-data" ref="domClimateData" >
    <h4 class="title">{{ title }}</h4>
    <div class="page-summary">
      <div 
        v-for="summaryEntry in page.summary"
        class="property"
        v-bind:key="summaryEntry.uniqeId"
      >
        <p class="property__label">{{ summaryEntry.title }} <Quickinfo v-if="summaryEntry.tooltip" :item="summaryEntry"/></p>
        <p class="property__value">{{ summaryEntry.value }}</p>
      </div>
    </div>
    <div class="page-chart">
      <div 
        class="chart"
      >
        <h4 class="chart-title">{{ page.body.headline  }} <Quickinfo v-if="page.body.tooltip" :item="page.body"/></h4>
        <div 
          v-for="(sectionItem, idx) in page.body.sections"
          v-bind:key="idx"
          class="chart-section"
          :class="sectionSizeClass(sectionItem, idx)"
        >
          <NumberedItems v-if="true || plot.type == 'bar'" :item="sectionItem" :index="idx" />
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>

import { NumberedItems } from "@/components/";
import { Quickinfo } from "@/components/";

export default {
  name: "PageCO2StorageCapacity",
  components: {
    NumberedItems,
    Quickinfo
  },
  computed: {
  },
  data() {
    return {
      
    }
  },
  watch: {
  },
  props: {
      mode: {
        type: String,
        default: ""
      },
      page: {
        type: Object,
        default: null
      },
      title: {
        type: String,
        default: ""
      }
  },
  created() {
  },
  methods: {
    sectionSizeClass(sectionItem, idx) {
      if ((idx==0 && sectionItem.trees > sectionItem.trees_max) /* || (idx==1 && sectionItem.trees > 100) */) {
         return "has-oversize";
      } else  {
        return "";
      }
    }
  }

};
</script>