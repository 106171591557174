<template>
    <div class="help-content-part help-content-part--filter">
      <p class="help-title">{{ getContent('TEXT_TERMS_FILTER', true) }}</p>
      <Helpitem       
        v-for="(help, idx) in filtersFlat"
        :key="'filterHelpItem' + idx"
        :item="help"
        :title="help.translation"
        :text="help.help_text"
        type="filter"
      >
      </Helpitem>    
    </div>
</template>

<script>
import Helpitem from "./Helpitem.vue";
import { ContentController } from "@/controller";

export default {
  name: "HelpFilter",
  components: {
    Helpitem
  },
  computed: {
    filtersFlat() {
      const flattend = [];
      if (this.filters) this.filters.forEach(group => group.forEach(filter => flattend.push(filter)))
      else [];
      return flattend;
    }
  },
  created() {
  },
  data() {
    return {
    }
  },
  props: {
    filters: {
      type: Array,
      default: null
    }
  },  
  methods: {
    collapseProperties() {
      this.propertiesStyle = "height: " + this.$refs.domPropertiesList.getBoundingClientRect().height + "px;";
      this.hasPropertiesTransition = true;
      this.$nextTick(() => {
        this.propertiesStyle = "height: " + this.getPropertiesCollapsedHeight() + "px;";
        this.propertiesExpanded = false;
      });
    },
    expandProperties() {
      this.style = "height: " + this.getPropertiesCollapsedHeight() + "px;";
      this.hasPropertiesTransition = true;
      this.propertiesExpanded = true;
      this.$nextTick(() => {
        this.propertiesStyle = "height: " + this.$refs.domPropertiesList.getBoundingClientRect().height + "px;";
      });
    },    
    getContent(content, clean) {
      return ContentController.getContent(content, clean);
    }        
  }
};
</script>
