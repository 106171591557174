<template>
    <input type="hidden" v-model="value" :name="item.name" @change="onChange($event, item)" />  
</template>

<script>

export default {
  name: "InputHidden",
  computed: {
  },
  props: {
      handles: {
          type: Array,
          default: null
      },
      item: {
          type: Object,
          default: null
      }
  },
  data() {
    return {
      value: "",
    }
  },
  created() {
  },
  methods: {
    onChange(ev, item) {
      this.$emit("onChange", ev, item, this.value);
    }
  },
};
</script>