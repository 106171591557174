<template>
  <div class="form-container" :class="{ 'is-active': editFormOnResult }" >
    <div class="form-container__inner">
      <!-- <ExpertNav /> -->
      <ButtonClose v-if="editFormOnResult" @click="handleCloseClick" />
      <ConfiguratorForm :mode="mode" /> <!-- :formMode="formMode" -->
    </div>
  </div>
</template>

<script>
import ConfiguratorForm from "./ConfiguratorForm.vue";
import { ButtonClose } from "@/components"; 
import { ConfiguratorFormController } from "@/controller";
//import ExpertNav from "@/views/layout/components/Expertnav.vue";

export default {
  name: "FormContainer", 
  components: {
    ConfiguratorForm,
    ButtonClose,
    //ExpertNav
  },
  computed: {
    mode() {
      return this.$store.state.app.mode;
    },    
    /* formMode() {
      return ConfiguratorFormController.getFormMode(); //this.$store.state.app.configuratorFormMode;
    }, */
    editFormOnResult() {
      return this.$store.state.app.editFormOnResult;
    },    
  },
  created() {
  },
  data() {
    return {
    }
  },
  methods: {
    handleCloseClick() {
      this.$store.dispatch("setEditFormOnResult", false);
      console.log("FormContainer:handleCloseClick");
      ConfiguratorFormController.resetAndClearUndoValues();
    }
  }
};
</script>
