<template>
  <div class="result-container" :class="{ 'is-detail': detailItem }">
    <Help :filters="filters" class="help-filters" :show="filterHelpActive" :modal="true" />
    <Help :filters="filters" :systemhelp="systemhelp" class="help-mobile" :show="mobileHelpActive" :modal="true" @onToggleHelp="mobileHelpActive = !mobileHelpActive" />
    <ConfiguratorResult :mode="mode" :class="{ 'is-active': !detailItem }" @onItemDetailClick="handleItemDetailClick" />
    <ConfiguratorDetail :mode="mode" :item="detailItem" :class="{ 'is-active': detailItem }" />
  </div>
</template>

<script>

import { ConfiguratorResultController } from "@/controller";
import ConfiguratorResult from "./ConfiguratorResult.vue";
import ConfiguratorDetail from "./ConfiguratorDetail.vue";
import { EventBus } from "@/bus/eventbus.js";
import { Help } from "./help";

export default {
  name: "DynContainer",
  components: {
    ConfiguratorResult,
    ConfiguratorDetail,
    Help
  },
  computed: {
    mode() {
      return this.$store.state.app.mode;
    },    
    showDetail() {
      return this.$store.state.app.showDetail;
    }
  },
  data() {
    return {
      detailItem: null,
      filters: [],
      systemhelp: [],
      filterHelpActive: false,
      mobileHelpActive: false
    }
  },
  created() {
    EventBus.$on("onDetailBackButtonClick", this.handleDetailButtonBackClick);
    EventBus.$on("onSetupConfiguratorFilterAndHelp", () => {
      this.filters = ConfiguratorResultController.filterItems;
      this.systemhelp = ConfiguratorResultController.systemHelp;
      console.log("ResultContainer:created:this.sytemhelp", this.systemhelp);
    });
    EventBus.$on("onToggleHelp", (type) => {
      //console.log('onToggleHelp type', type);
      if (type=="filter") this.filterHelpActive = !this.filterHelpActive;
      if (type=="mobile") this.mobileHelpActive = !this.mobileHelpActive;
    });
    EventBus.$on("onBeforeUpdateResult", () => {
        //reactivate the overview and leave the detail
        this.detailItem = null;
        this.$store.dispatch("setShowDetail", "");
    });    
  },
  methods: {
    handleItemDetailClick(item) {
      console.log("ResultContainer[handleItemDetailClick]", item.pages);
      this.detailItem = item;
      this.$store.dispatch("setShowDetail", item.uniqeId);
      this.$nextTick(() => {
        //console.log("ResultContainer emit onShowDetail", item);
        EventBus.$emit("onShowDetail", item);
      });
      
    },
    handleDetailButtonBackClick() {
      this.detailItem = null;
      this.$store.dispatch("setShowDetail", "");
    },
  }
};
</script>
