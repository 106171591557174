<template>
  <div class="detail" ref="domDetail" :data-active-page="activePage">
    <PageSystem v-if="item && activePage == 'SYSTEM'" :page="getPageByPageId('SYSTEM')" :item="item" />
    <PageClimateData v-if="item && activePage == 'CLIMATE'" :page="getPageByPageId('CLIMATE')" :title="titleForPages" />
    <PageEnergyBalance v-if="item && activePage == 'ENERGY'" :page="getPageByPageId('ENERGY')"  :title="titleForPages" @onCalculationChanged="handleCalculationChanged" />
    <PagePerformanceData v-if="item && activePage == 'POWER'" :page="getPageByPageId('POWER')"  :title="titleForPages"/>
    <PageHeatBalance v-if="item && activePage == 'HEAT'" :page="getPageByPageId('HEAT')"  :title="titleForPages" />
    <PageCO2StorageCapacity v-if="item && activePage == 'CO2'" :page="getPageByPageId('CO2')" :title="titleForPages" />
    <PageRequest v-if="item && activePage == 'REQUEST'" :item="item" :page="getPageByPageId('REQUEST')" :title="titleForPages" />
    <!-- <Pdf v-if="false" :item="item" :title="titleForPages" :style="'height: 0; overflow: hidden;'" /> -->
  </div>
</template>

<script>

import { PageSystem, PageClimateData, PageEnergyBalance, PagePerformanceData, PageHeatBalance, PageCO2StorageCapacity, PageRequest } from "./pages";
//import Pdf from "./pdf";
import { EventBus } from "@/bus/eventbus.js";
import { PdfController } from "@/controller/";

export default {
  name: "Detail",
  components: {
    PageSystem,
    PageClimateData,
    PageEnergyBalance,
    PagePerformanceData,
    PageHeatBalance,
    PageCO2StorageCapacity,
    PageRequest,
    //Pdf
  },
  computed: {
    itemSubline() {
      if (this.detailSelected) {
        if ( Array.isArray(this.detailSelected.subline)) {
          return this.detailSelected.subline.join(" ");
        } else {
          return this.detailSelected.subline;
        }
      } else {
        return "";
      }
    },
    titleForPages() {
      if (this.item && this.item.pages && this.item.pages[0] && this.item.pages[0].summary && this.item.pages[0].summary.length) {
        let title = "";
        this.item.pages[0].summary.forEach(item => title += (title && item.detail ? ", " : "") + (item.detail ? item.headline : ""));
        return  title; //this.item.pages[0].summary[0].headline + ', ' + this.item.pages[0].summary[0].subline;
      } else {
        return "";
      }
    }
  },
  data() {
    return {
      selected: "",
      propertiesExpanded: false,
      hasPropertiesTransition: false,
      propertiesStyle: "",
      activePage: "System",
      pdf: "",
    }
  },
  watch: {
  },
  props: {
      mode: {
        type: String,
        default: ""
      },
      item: {
        type: Object,
        default: null
      }
  },
  created() {
    EventBus.$on("onPageChange", page => {
      //this.$tooltip.close();
      console.log('Detail onPageChange', page);
      this.setActivePage(page);
    });    
    EventBus.$on("onShowRequest", () => this.handleShowRequest());
  },
  methods: {
    getPageByName(title) {
      if (this.item && this.item.pages) {
        const fPages = this.item.pages.filter(p => p.menu_name == title);
        if (fPages.length) {
          return fPages[0];
        } else {
          return undefined;
        }
      } else {
        return undefined;
      }
    },
    getPageByPageId(id) {
      if (this.item && this.item.pages) {
        const fPages = this.item.pages.filter(p => p.page_id == id);
        if (fPages.length) {
          return fPages[0];
        } else {
          return undefined;
        }
      } else {
        return undefined;
      }
    },
    setActivePage(page) {
      this.activePage = page.page_id;
      this.$store.dispatch('setDetailPage', this.activePage);
      /* const fPages = this.item.pages.filter(p => p.uniqeId == page.uniqeId);
      if (fPages.length) {
        this.activePage = fPages[0].menu_name;
      } */
    },
    handleShowRequest() {
      this.setActivePage(this.getPageByPageId('REQUEST'));
    },
    handlePdfGenerated() {
      //this.$emit("onPdfGenerated");
    },
    handleCalculationChanged() {
        //the new values must be put into the pdf
      PdfController.resetPdf();
    },
  },
};
</script>
