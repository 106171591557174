<template>
  <div class="wrapper-imageslider" :class="{ 'has-slider': isSlider }" >
    <div class="wrapper-imageslider__inner">
      <div 
          class="imageslider" 
          :class="{ 'has-slider': isSlider , 'is-sliding': this.mouseMoving }" 
          ref="domImageslider" 
          :style="sliderStyle" 
          @mousedown="handleMousedown" 
          @mouseup="handleMouseup" 
          @mousemove="handleMousemove"
        >
        <figure 
          v-for="(image, idx) in item.pictures"
          :data-index="idx"
          :key="'slideimage_' + idx"
          class="image"
          :style="imageStyle"
        >
          <img :src="getImageSource(image)" :alt="item.headline" :title="item.headline" />
        </figure>
      </div>
    </div>
    <div class="imageslider__detail">
      <ul class="imageslider__slides">
        <li 
            v-for="(image, idx) in item.pictures"
            :data-index="idx"
            :key="'slideimage_slide_' + idx"
            :class="{ 'is-active': slideIndex == idx }"
        >
        </li>
      </ul>
      <ul class="imageslider__titles">
        <li 
            v-for="(image, idx) in item.pictures"
            :data-index="idx"
            :key="'slideimage_title_' + idx"
            :class="{ 'is-active': slideIndex == idx }"
        >
          {{ image.title }}
        </li>
      </ul>
    </div>
    <button class="button--icon button--slide-left button--slider" @click="slideLeft" :disabled="slideIndex==0" v-if="isSlider">
      <i class="svgicon svgicon--chevron" v-html="iconChevron"></i>    
    </button>
    <button class="button--icon button--slide-right button--slider" @click="slideRight" :disabled="slideIndex==item.pictures.length-1" v-if="isSlider">
      <i class="svgicon svgicon--chevron" v-html="iconChevron"></i>    
    </button>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import { ImageController } from "@/controller";
import { EventBus } from "@/bus/eventbus.js";

export default {
  name: "Imageslider",
  components: {
  },
  computed: {
    ...mapGetters(["gSize"]),
    itemSubline() {
      if (this.detailSelected) {
        if ( Array.isArray(this.detailSelected.subline)) {
          return this.detailSelected.subline.join(" ");
        } else {
          return this.detailSelected.subline;
        }
      } else {
        return "";
      }
    },
    sliderStyle() {
      let left = this.imageWidth*this.slideIndex - (this.mouseMoving?this.moveDelta:0);
      let style = "width: " + this.sliderWidth + "px; transform: translate(-" + left +  "px, 0);";
      return style;
    },
    imageStyle() {
      const w = (this.imageWidth==0?"100%":this.imageWidth + "px;")
      return "width: " + w + " flex: 0 0 " + w;
    },
    isSlider() {
      return this.item.pictures.length>1;
    }
  },
  data() {
    return {
      imageWidth: 0,
      sliderWidth: 0,
      iconChevron: '<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.59 1.41L5.17 6L0.59 10.59L2 12L8 6L2 -2.62268e-07L0.59 1.41Z" fill="white"/></svg>',
      slideIndex: 0,
      mouseX: 0,
      mouseMoving: false,
      moveDelta: 0
    }
  },
  watch: {
    item: function() {
      this.slideIndex = 0;
      this.resize();
    },
    active: function() {
      if (this.active) {
        this.slideIndex = 0;
        this.resize();
      }
    }
  },
  props: {
      mode: {
        type: String,
        default: ""
      },
      item: {
        type: Object,
        default: null
      },
      active: {
        type: Boolean,
        default: false
      }
  },
  created() {    
    EventBus.$on("doResize", () => {
      this.resize();
    });
  },
  mounted() {
    this.resize();
  },
  methods: {
    getImageSource(image) {
      return ImageController.getItemImageSrc(image);
    },
    resize() {
      //console.log("Imageslider[resize]", this.$refs.domImageslider);
      const w = (this.$refs && this.$refs.domImageslider && this.$refs.domImageslider.parentNode ? this.$refs.domImageslider.parentNode.getBoundingClientRect().width : 0);
      this.imageWidth = w;
      this.sliderWidth = w * this.item.pictures.length;
    },
    slideLeft() {
      if (this.slideIndex > 0) {
        this.slideIndex--;
      }
    },
    slideRight() {
      if (this.slideIndex < this.item.pictures.length-1) {
        this.slideIndex++;
      }
    },
    handleMousedown(ev) {
      this.mouseMoving = true;
      this.mouseX = ev.pageX;
      this.moveDelta = 0;
    },
    handleMousemove(ev) {
      if (this.mouseMoving) {
        this.moveDelta = ev.pageX - this.mouseX;
        if (this.moveDelta >= 60 || this.moveDelta <= -60) {
          if (this.moveDelta < 0) {
            this.resetMoving();
            this.slideRight();
          } else {
            this.resetMoving();
            this.slideLeft();
          } 
        }
      }
    },
    handleMouseup() {
      this.resetMoving();
    },
    resetMoving() {
      this.mouseMoving = false;
      this.moveDelta = 0;
      this.mouseX = 0;
    },
  },
};
</script>
