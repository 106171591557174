<template>
  <div class="page page-climate-data" ref="domClimateData" >
    <h4 class="title">{{ title }}</h4>
    <div class="page-summary">
      <div 
        v-for="summaryEntry in page.summary"
        class="property"
        v-bind:key="summaryEntry.uniqeId"
      >
        <p class="property__label">{{ summaryEntry.title }} <Quickinfo v-if="summaryEntry.tooltip" :item="summaryEntry"/></p>
        <p class="property__value">{{ summaryEntry.value }}</p>
      </div>
    </div>
    <div class="page-chart page-chart-climate-data">
      <div 
        class="chart"
        v-for="(plot,idx) in page.plots"
        v-bind:key="idx"
        ref="domChart"
      >
        <h4 class="chart-title">{{ plot.title }}</h4>
        <Bar v-if="plot.type == 'bar'" :plot="plot" :maxHeight="maxHeight" :chartHeight="chartHeight" :chartWidth="chartWidth"/>
      </div>
    </div>
  </div>
</template>

<script>
import Store from "@/store";
import { Bar } from "@/components/";
import { Quickinfo } from "@/components/";
import { EventBus } from "@/bus/eventbus.js";

export default {
  name: "PageClimateData",
  components: {
    Bar,
    Quickinfo
  },
  computed: {
    itemSubline() {
      if (this.detailSelected) {
        if ( Array.isArray(this.detailSelected.subline)) {
          return this.detailSelected.subline.join(" ");
        } else {
          return this.detailSelected.subline;
        }
      } else {
        return "";
      }
    }
  },
  data() {
    return {
      selected: "",
      detailSelected: null,
      propertiesExpanded: false,
      hasPropertiesTransition: false,
      propertiesStyle: "",
      maxHeight: 595,
      chartHeight: 0,
      chartWidth: 0,
      screenWidth: 0,
    }
  },
  watch: {
  },
  props: {
      mode: {
        type: String,
        default: ""
      },
      page: {
        type: Object,
        default: null
      },
      title: {
        type: String,
        default: ""
      }
  },
  created() {
    console.log('this.page', this.page);
    EventBus.$on("doResize", () => {
      console.log("ClimateData:doResize:", this.screenWidth, Store.state.app.size.screen.width);
      if (this.screenWidth != Store.state.app.size.screen.width) {
        this.getChartHeight();
        this.getChartWidth();
      }
      this.screenWidth = Store.state.app.size.screen.width;
    });
    /* if (this.page  && this.page.summary.length && !this.detailSelected) {
      console.log('page', this.page);
      this.detailSelected = this.page.summary[0].detail;
    }  */
  },
  mounted() {
    this.getChartHeight();
    this.getChartWidth();
    this.screenWidth = Store.state.app.size.screen.width;
  },
  methods: {
    getChartHeight() {
      console.log("ClimateData:getChartHeight:gSize", Store.state.app.size.screen.width);
      this.chartHeight = (this.$refs.domChart.length ? this.$refs.domChart[0].getBoundingClientRect().height:0) - (Store.state.app.size.screen.width < 1024 ? 24 : 122);
    },
    getChartWidth() {
      this.chartWidth = (this.$refs.domChart.length ? this.$refs.domChart[0].getBoundingClientRect().width:0) - (Store.state.app.size.screen.width < 1024 ? 24 : 109);
    }
  }

};
</script>