<template>
    <div class="google-map-wrapper">
      <div class="google-map" ref="googleMap"></div>
      <template v-if="Boolean(this.google) && Boolean(this.map)">
        <slot
            :google="google"
            :map="map"
        />
      </template>
    </div>
</template>

<script>
import GoogleMapsApiLoader from "google-maps-api-loader";

export default {
    props: {
        mapConfig: Object,
        mapCenter: {
            type: Object,
            default: null
        },
        mapHome: {
            type: Object,
            default: null
        },
        mapStyles: Object,
        mapMarkers: Array,
        markerSelected: Number,
        apiKey: String,
    },
    data() {
        return {
            google: null,
            map: null,
            icons: {
                markerStandard: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAoCAYAAADpE0oSAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMXSURBVHgBtZjPaxNBFMffm2ypiq0BBbGCrCe9CFHorYdE/4BaqhW9aMFLK6L9B5rNX2BFpOKlehKqoj14tI3QW8EGvOjJRVDxIEaM2NTNPudtu2lSdzMvP/YDIbOzb+Y78+bN7NtFaBNn/oUNnmeDIhuQylBTZV0uOzcmSu30gxIj597TrOpTV8mn87pFOqYrV3dW9D3/sXPzYtHUJ5oE0cK8LmahLdAl8mac6UsvYy0iBRcW0qo6mCei29AFSDg3Oz0+E3kvShSrAytAkIFegFTSsx9zpi67scI9F20U76/knMnJclilmgw2Bu/0XJQhzCjuu3EsYcG5v3gNlVqABCGPcmHE12eMKpWHhEFrZ2KBMM9Wj8eGxCGbtyiXrOA6lRoFIlHTz58+wtrqa/j+7StUqxswdOw4nDx1JvhJ2D4XioEw+pSVnGEsuLa63FT3RQ+Ef79+/oDhkXMgIAhedB4uZrCm1k3W79+9heVXz1vajF65Dke1B0yk+vbaCmqYBgEsbII9IsHbrJxW4MuCit1pgtddBGFa6X1Ultj29+8x2nCwifDRVWD5rsT24OEjRhvJ+oYo+N3nSgwlEXtCuKVgf6WknJkxdrVrsuXZtBLne6K9TFDih4W1VYYlvY1vmdoMj5yFgQNp+KAjnA8SXvdDeglYdEjoZkJ4w//W9qXOFNAozLRzSkXiUZCV1M+rwoNn64k8EptANz81Hrim/nTSac4SJAxCrRCWdxKBDWtOz1i0pzuUdX2oFf8T5ugmpLuQEOTrtLch79qV+iQ1a3RhX2WusaZJmGftp6gAPYbXtjHR26qLQEc4Z5pZ6I1sPZIbUVGmVPMjk/BOINjMRdVHCvMLGEH3LtfvWoXdiXxIy4SnO5dHuzhEtWpK9HeyoyjXbeJcLBJmNxG2v96o28S5WCS8JT7xSD+9xAcLr+usbmOyE72YM5L11gMsOlMXciDAOON6p39SY8EJFAvf0zEhRCwcnOVBwESJ6y8A+p5pXZtaQJvo96wMolqpfwvhCCY/l8jHl1hx1u1AtCuc+Sc2DwA65B8rm0oF2mKX9wAAAABJRU5ErkJggg==",
                markerHighlight:  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAoCAYAAADpE0oSAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKvSURBVHgBvVi9ctNAEP7uyAxNMjgdBYWZoaIyFa0FBXRgXiDJA/CTitKTkioBHgB7hjpDOijAoqSKK2hSqKGgigZS0EibvTvZlhPZt5KlfDNnS9befrc/t7eWQknQabfNXzw0DxUDCQ/EajMcl1ADJRFisi603gLhKd+2FohFrC1Emg55EaFPp/ISKtVnsS7KIQKpXbX59RPKEDNhiy3ss4WvsAoUDtSNb7vFj4pI1bURX3VQC9QYlPTY/dFC4vpJ58gDJo8nv+i551rv109qwDqt7txSpo9OH2zz3Qc0CUqDScbr3BL6aBpKTw2zxNZaWxQaR9tuUcaaWwmeSGf+GBPeDwk/Twj/zoD7HYVnj3g8FtUiuLqAcC277UrmGMJ3g/TSQsz4/Ufj+ZaEXNnkVWx6h31/7BM//Ex4/SZdKvNxX1sPeHEdbRPjFgQ4/EJeGeMREf6n97Q7Zfww7vTh14mQGLql3dHmx8a6X+bvGYRIIyZOIono3Tv+2Inim8HEOJIISjLWbCshxjor3JFP0lizjNw8k+1lGhtOt48JR1xEXvqmvNjWuHWTbIabZNtYVxwCRyp2M6nv5stKu05Dj3AVyA6K2ekUB8eTqtIga6Rao9vmanY6kTpC0yDam1zmGoH0gD9Ee7oia8Qf4SVim92Et2gKpIb5vmu+9WnMamOt1Y1C4szqPdQNjm2+0bNchXLxQ9NpdlEP6zST89DFsklhE16Nl4KinwuJ7R+wOlzOOi428lOOpfNWcnmxiyfQy+cmO6iW5fEiF4uIrZsoLR9vnrPIxSLijHxQqrC4uA58YuKTWxZvCjmuAQTwWjzTmfSyertIgMNCOxBCTOyqmkmYInJLGvjiWok4IzcEbPlcppsF9cqQWl2ogOzfh+tYXEdR6o3PSjCvnewCKuIc1usZkalLiyYAAAAASUVORK5CYII=",
                markerHome: " data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMWSURBVHgB7VjbldowEJ1RPnLyk/VWEG8FYSuIgQKWrQAogQp2U8GWsFBBSAEBdxBSAd4KbPKVn2gyevCIseQX+cge33OEQdLMXKTRaMYAHTp0+L+BcAFQGoX84Ca4YQbwmxts8DrOoCUaE2RSPRBiDAQjQ64QCVuIQcrPTDaBBqhNkIkFTOyJiU1OunmliMnghvsDVsuNetwfnFiaNyFaiyCTiwDFF2s4YzILALlko7FzPuCEiY9tVwKEM7z+toSKqEyQfkZjkGJuf8VANK26GtpHkVcdtDuwq8oJvo8XcClQOhxRNiDd0sEjNISSPepRq3sRclHICrdtyR31HUim2p9LULrFtBs82wOxxGB1X2xUrYbgUw0fdYeEF/6Yu1yAsuGaPyN1cPBqNYWmBK3vbM0PeZM3qFcAxTPsfetce+HJtXq/gzpsJG95fAMOCPBBiIe9IY+RkVNerTyKdX4rtS4dAbSRCfgo+AZZSaSfUi4c5EMoR2hXOQdpQg3CnU/YucV2+1L+mrHvXefGjltfFST7+XipDgqobX4LIb5bvRSJ+VawZ9UU+Id/W4oheud9mOjHL3njlAIn3hi/Ididj1EIdbE/4X+pkT/sYOgWc4IMQYEp/CsgXpkv0pn1eAjKRD+ICraGNlAXOjbmgfvT3YQgX+xGSVgwVp8gB+7zvsOfrx8HbdxTLcjfm/Y0Vs5IHHE0Ap0VUeJLbMvi4Fc77TwYk5zqHLAUPIdvk3PLwqRgiLFP2k9wvy0IY5vWH6D/NVGfmy9tWvL4beEttE94i8if2oESUNqfm4STYgzW/eI5ijzHRgEfrFEVPjbORDYbqKR3VCVZKIW6UXRqpFKk3eABWkLpMOlWf5vfleZK2aEPiWYLkkdyKrccjqrIVE/502hycumzb8lZvZQfWRYj0yFVuTCvIlu3aGK/wadDbDT53sJbNAlxZw+ELbTkvWt+a4LWaMhijyeVGoApOze6aEdFAnv2vs6XnbO6xXybwj00ROGT47ZRSEws1el/g9vncq8+1ErZQl0E9h5Pmr5N6NChw2vCH4WBjgV5rfHzAAAAAElFTkSuQmCC"
            },
            markersOnMap: [],
            oldSelected: -1,
            homeMarker: {}
        }
    },
    async mounted() {    
        if (!window.google) {
            const googleMapApi = await GoogleMapsApiLoader({
                apiKey: this.apiKey
            });
            this.google = googleMapApi;
        } else {
            this.google = window.google;
        }
        this.initializeMap();
    },
    watch: {
        mapCenter: function() {
            this.setMapCenter();
        },
        mapStyles: function() {
        },
        mapMarkers: function() {
            this.setMapMarkers();
        },
        mapHome: function() {
            this.setHomeMarker();
        },
        markerSelected: function() {
            this.resetMarkerIcon(this.oldSelected);
            this.setMarkerIcon(this.markerSelected);
            this.oldSelected = this.markerSelected;
        }
    },
    methods: {
        initializeMap() {
            const mapContainer = this.$refs.googleMap;
            this.map = new this.google.maps.Map(mapContainer, this.mapConfig);
            this.setMapTypeStyles();
            this.setHomeMarker();
            this.setMapCenter();
        },
        setMapCenter() {
            this.map.setCenter(new this.google.maps.LatLng(this.mapCenter.lat, this.mapCenter.lng));
        },
        setMapTypeStyles() {
            /* const mapType = new this.google.maps.StyledMapType({
                featureType: "all",
                elementType: "all",
                stylers: [
                    { saturation: -100 } // <-- THIS
                ]
                }, {
				name: "Grayscale"
			});
			this.map.mapTypes.set("Grayscalez", mapType);
			this.map.setMapTypeId("Grayscalez"); */
        },
        setMapMarkers() {
            this.removeMarkers();
            const icon = {
                size: new this.google.maps.Size(26, 36),
                scaledSize: new this.google.maps.Size(26, 36),
                origin: new this.google.maps.Point(0, 0),
                url: this.icons.markerStandard,
                anchor: new this.google.maps.Point(13, 18)   
            }
            const self = this;
            this.mapMarkers.forEach(marker => {
                if (marker.selected) {
                    icon.url = this.icons.markerHighlight;
                } else {
                    icon.url = this.icons.markerStandard;
                }
                const m = new this.google.maps.Marker({
                    position: marker.position,
                    marker: marker,
                    map: this.map,
                    icon: icon
                });
                this.google.maps.event.addListener(m, 'click', () => {
                    self.$emit("markerClicked", m.marker.id);
                });
                this.markersOnMap.push(m);
            });
        },
        setHomeMarker() {
            if (this.homeMarker && typeof this.homeMarker.setMap == "function") this.homeMarker.setMap(null);
            const icon = {
                size: new this.google.maps.Size(36, 36),
                scaledSize: new this.google.maps.Size(36, 36),
                origin: new this.google.maps.Point(0, 0),
                url: this.icons.markerHome,
                anchor: new this.google.maps.Point(13, 18)   
            }
            //const self = this;
            //this.mapMarkers.forEach(marker => {
                icon.url = this.icons.markerHome;
                const m = new this.google.maps.Marker({
                    position: this.mapHome,
                    marker: this.mapHome,
                    map: this.map,
                    icon: icon
                });
                this.homeMarker = m;
            //});
        },
        removeMarkers() {
            this.markersOnMap.forEach(marker => marker.setMap(null));
            this.markersOnMap = [];
        },
        resetMarkerIcon(index) {
            this.changeMarkerIcon(index, this.icons.markerStandard);
        },
        setMarkerIcon(index) {
            this.changeMarkerIcon(index, this.icons.markerHighlight);
        },
        changeMarkerIcon(index, icon) {
            if (index != -1) {
                this.markersOnMap[index].setMap(null);
                this.markersOnMap[index].icon.url = icon;
                this.markersOnMap[index].setMap(this.map);
            }
        }
    }
}
</script>