<template>
  <div class="form-property" @transitionend="onTransitionend" :class="{ 'is-folding': folding, 'is-disabled': disabled }" :style="style" >
    <div class="property-inner" ref="domInner">
      <div class="check-box check-box--regular" :class="{ 'is-selected': value}">        
          <input 
            type="checkbox" 
            v-model="value" 
            :name="item.name"
            :id="item.name"
            :value="true"
            @change="onChange($event, item)"
            :class="{ 'is-selected': value}"
          />
          <label :for="item.name" >
              <span class="check">
                <i class="svgicon svgicon--check">
                  <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 6.5L6 11.5L16.5 1" stroke="#171F27" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                </i>
              </span>            
              {{ item.label }} <Quickinfo v-if="item.quickinfo" :item="item"/>
          </label>
      </div>
    </div>
  </div>
</template>

<script>
//import { ConfiguratorFormController } from "@/controller";
import { Quickinfo } from "@/components/";

export default {
  name: "Checkbox",
  components: {
    Quickinfo
  },  
  computed: {
    style() {
      const height = this.foldingHeight == "auto" ? "auto" : this.foldingHeight + "px;";
      return "height: " + height;
    }
  },
  props: {
      handles: {
          type: Array,
          default: null
      },
      item: {
          type: Object,
          default: null
      },
      storedValue: {
        type: Object,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      }
  },
  data() {
    return {
      value: false,
      folding: false,
      foldingHeight: "auto"
    }
  },
  created() {
    if (this.storedValue.value){
      this.value = this.storedValue.value
    }
  },
  mounted() {
  },
  methods: {
    onChange(ev, item) {
      // console.log('CheckBox:onChange:this.value', this.value);
      this.$emit("onChange", ev, item, this.value);
    },
    onTransitionend() {
      this.folding = false;
      this.foldingHeight = "auto";
    },    
  },
};
</script>