<template>
    <div 
      class="collapse-on-mobile"
      :class="{ 'is-expanded': expanded, 'has-transition': hasTransition }"
      :data-type="type"
    >
      <p class="collapse-permanent" :class="{ 'is-expanded': expanded }" @click="onClick">
        <span v-if="item.icon" class="title-icon">
          <Cardicon :icon="item.icon" />
        </span>
        <span>{{ title }}</span>
        <i class="svgicon svgicon--chevrondown">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.41 8.59L12 13.17L16.59 8.59L18 10L12 16L6 10L7.41 8.59Z" fill="white"/>
          </svg>
        </i>
      </p>
      <div class="collapse-flexible" :style="style" >
        <p class="collapse-inner" ref="domFlexibleInner">
          {{ text }}
        </p>
      </div>
    </div>
</template>

<script>
import Cardicon from "../../list/Cardicon.vue";

export default {
  name: "Helpitem",
  components: {
    Cardicon
  },
  computed: {    
  },
  created() {
  },
  data() {
    return {
      expanded: false,
      hasTransition: false,
      style: "",
      collapsMaxWidth: 1024 
    }
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    }
  },  
  methods: {
    onClick() {
      if (this.expanded) {
        this.collapse();
      } else {
        this.expand();
      }
    },
    collapse() {
      console.log('this.$store.state', this.$store.state);
      if (this.$store.state.app.size.screen.width < 1024) {
        this.style = "height: " + this.$refs.domFlexibleInner.getBoundingClientRect().height + "px;";
        this.hasTransition = true;
        this.$nextTick(() => {
          this.style = "height: 0;";
          this.expanded = false;
        });
      }
    },
    expand() {
      if (this.$store.state.app.size.screen.width < 1024) {
        this.style = "height: 0;";
        this.hasTransition = true;
        this.expanded = true;
        this.$nextTick(() => {
          this.style = "height: " + this.$refs.domFlexibleInner.getBoundingClientRect().height + "px;";
        });
      }
    },    
  }
};
</script>
