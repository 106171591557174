<template>
  <div class="systemselect">
    <div class="selected" @click.prevent="handleSelectClick" :class="{ 'is-active': listActive }">
      <p>
        <span class="icon" v-if="selectedItem && selectedItem.icon">
          <Cardicon :icon="selectedItem.icon" />
        </span>
        {{ (selectedItem.headline?selectedItem.headline:"") }}
      </p>   
      <i class="svgicon svgicon--chevrondown svgicon--trigger" :class="{ 'is-active': listActive }">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.41 8.59L12 13.17L16.59 8.59L18 10L12 16L6 10L7.41 8.59Z" fill="white"/>
        </svg>
      </i>
    </div>
    <div class="selectlist-wrapper" :class="{ 'is-active': listActive }" :style="listHeightStyle">
      <div class="selectlist" ref="domSelectList">
        <div
          v-for="(systemOption) in summaries"
          :key="systemOption.uniqeId"
          class="selectlist__item"
          :class="{ 'is-selected': (selectedItem.uniqeId == systemOption.uniqeId)}"
          @click="handleSelectListItemClick(systemOption)" 
        >
          <p>
            <span class="icon" v-if="systemOption && systemOption.icon">
              <Cardicon :icon="systemOption.icon" />
            </span>
            {{ (systemOption?systemOption.headline:'') }}
            <span class="icon icon--selected" v-if="selectedItem.uniqeId == systemOption.uniqeId">
              <i class="svgicon svgicon--check" v-html="icons.check"></i>
            </span>
          </p>
          <div v-if="systemOption.subcards">
            <p
              v-for="(subOption) in systemOption.subcards"
              :key="subOption.uniqeId"
              class="selectlist__subitem"
            >
              <span class="icon" v-if="subOption && subOption.icon">
                <Cardicon :icon="subOption.icon" />
              </span>
              {{ (subOption?subOption.headline:'') }}          
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Cardicon from "../../../list/Cardicon.vue";

export default {
  name: "SystemSelect",
  components: {
    Cardicon
  },  
  computed: {
    listHeightStyle() {
      return "height: " + this.listHeight + "px;";
    }
  },
  props: {
      summaries: {
        type: Array,
        default: null
      },
      selected: {
        type: String,
        default: ""
      },
  },
  data() {
    return {
      listActive: false,
      selectedItem: {},
      listHeight: 0,
      icons: {
        "check": '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 6L9 17L4 12" stroke="#8197AC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
      }
    }
  },
  watch: {
  },
  created() {
  },
  mounted() {
    if (!this.selectedItem.headline && this.summaries.length) {
      this.selectedItem = this.summaries[0];
    }
  },
  methods: {
    handleSelectClick() {
      this.toggleSelect();
    },
    toggleSelect() {
      if (!this.listActive) {
        this.listHeight = (this.$refs.domSelectList ? this.$refs.domSelectList.getBoundingClientRect().height : 0);
      } else {
        this.listHeight = 0;
      }
      this.listActive = !this.listActive;
    },
    handleSelectListItemClick(systemOption) {
      this.selectedItem = systemOption;
      this.toggleSelect();
      this.$emit("detailChange", this.selectedItem);
      /* if (ev.target.nodeName == "LI") {
        this.selectedValue.value = this.value = ev.target.dataset['value'];
        this.selectedValue.text = ev.target.innerHTML.trim();
        this.listActive = false;
        this.$emit("onChange", ev, this.item, this.value);
      } */
    }
  },
};
</script>