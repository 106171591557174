<template>
  <div class="form-property" :class="{ 'is-disabled': disabled, 'has-error': item.hasError }">
    <p class="form-property__label">{{ item.label }} <Quickinfo v-if="item.quickinfo" :item="item"/></p>
    <p class="form-error" v-show="item.hasError" v-html="errorMsg"></p>
    <div class="check-button-group" :class="classes" :id="'checkButtonGroup' + item.uid">
      <div class="check-button-group__item"
        v-for="(option, idx) in item.options"
        :key="'option_' + option.uid + '_' + item.uid"
        :class="{ 'is-selected': values[idx], 'is-hidden': !option.show }"
      >
        <input 
          type="checkbox" 
          v-model="values[idx]" 
          :name="item.name + '_' + option.value"
          :id="item.name + '_' + option.value"
          :value="option.value"
           @change="onChange($event, item)"
           :class="{ 'is-selected': values[idx] }"
        />              
        <label :for="item.name + '_' + option.value" >
          {{ option.text }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/bus/eventbus.js";
import { Quickinfo } from "@/components/";
import { ContentController } from "@/controller";

export default {
  name: "CheckButtonGroup",
  components: {
    Quickinfo
  },  
  computed: {
    multiple() {
      return this.item.type.indexOf("multiple")!=-1;
    },
    direction() {
      return "horizontal";
    },
    classes() {
      return "check-button-group--" + this.direction + (this.multiple?" check-button-group--multiple":"") + (this.disabled?" is-disabled ":"");
    }
  },
  props: {
      handles: {
          type: Array,
          default: null
      },
      item: {
          type: Object,
          default: null
      },
      storedValue: {
        type: Object,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      }
  },
  data() {
    return {
      values: [],
      options: [],
      error: false,
      errorMsg: "Fehler bei der Eingabe"
    }
  },
  watch: {
    storedValue: {
      handler: function() {
        this.values = [];
        if (this.multiple) {
         this.item.options.forEach((option, idx) => this.values.push(this.storedValue && this.storedValue.value && this.storedValue.value[idx]?option.value:""));
        }     
      }
    }
  },  
  created() {
    if (this.item) {
      if (this.multiple) {
        this.item.options.forEach((option, idx) => this.values.push(this.storedValue && this.storedValue.value && this.storedValue.value[idx]?option.value:""));
      }
      //TODO not desired
      /*const selected = this.item.options.map((option, idx) => this.values[idx]?option.value:"");
        const selectedCount = selected.filter(item => item != "").length;  
      if (this.item.mandatory && selectedCount == 0) {
        this.error = true;
      }  */
    }
    EventBus.$on("onFormError", () => this.checkError());
  },
  methods: {
    onChange(ev, item) { 
      this.checkError();
      const selected = item.options.map((option, idx) => this.values[idx]?option.value:"");
      this.$emit("onChange", ev, item, selected);
    },
    checkError() {
      const selected = this.item.options.map((option, idx) => this.values[idx]?option.value:"");
      const selectedCount = selected.filter(item => item != "").length;
      if (this.item.show && this.item.mandatory && selectedCount == 0) {
        this.item.hasError = true;
        this.errorMsg = ContentController.getContent("ERROR_MSG_MANDATORY");
      } else {
        this.item.hasError = false;
      }
      this.$emit("onErrorChange", this.item.name, this.item.hasError);
    },    
  },
};
</script>