<template>
  <div class="help" :class="{ 'is-active': show }" :style="displayStyle">
    <div class="help-header">
      <h4>Hilfe</h4>
      <button class="button--help button--close" @click="onToggleHelp">
        <i class="svgicon svgicon--close" v-show="show">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.3334 2.5465L17.4534 0.666504L10 8.11984L2.54669 0.666504L0.666687 2.5465L8.12002 9.99984L0.666687 17.4532L2.54669 19.3332L10 11.8798L17.4534 19.3332L19.3334 17.4532L11.88 9.99984L19.3334 2.5465Z" fill="white"/>
          </svg>            
        </i>
        <i class="svgicon svgicon--help" v-show="!show">
          <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.33301 11.0002H7.66634V9.66683H6.33301V11.0002ZM6.99967 0.333496C3.31967 0.333496 0.333008 3.32016 0.333008 7.00016C0.333008 10.6802 3.31967 13.6668 6.99967 13.6668C10.6797 13.6668 13.6663 10.6802 13.6663 7.00016C13.6663 3.32016 10.6797 0.333496 6.99967 0.333496ZM6.99967 12.3335C4.05967 12.3335 1.66634 9.94016 1.66634 7.00016C1.66634 4.06016 4.05967 1.66683 6.99967 1.66683C9.93967 1.66683 12.333 4.06016 12.333 7.00016C12.333 9.94016 9.93967 12.3335 6.99967 12.3335ZM6.99967 3.00016C5.52634 3.00016 4.33301 4.1935 4.33301 5.66683H5.66634C5.66634 4.9335 6.26634 4.3335 6.99967 4.3335C7.73301 4.3335 8.33301 4.9335 8.33301 5.66683C8.33301 7.00016 6.33301 6.8335 6.33301 9.00016H7.66634C7.66634 7.50016 9.66634 7.3335 9.66634 5.66683C9.66634 4.1935 8.47301 3.00016 6.99967 3.00016Z" fill="#8197AC"/>
          </svg>
        </i>        
      </button>
    </div>
    <div class="help-content">
      <HelpSystem v-if="systemhelp" :systemhelp="systemhelp" />
      <HelpFilter v-if="filters" :filters="filters" />
      <p class="help-support-info" v-if="false">
        <i class="svgicon svgicon--email">
          <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="16px" viewBox="0 0 20 16" enable-background="new 0 0 20 16" xml:space="preserve"><path fill="#FFFFFF" d="M18,0H2C0.9,0,0,0.9,0,2l0,12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V2C20,0.9,19.1,0,18,0z M18,14H2V4l8,5l8-5V14z M10,7L2,2h16L10,7z"/></svg>
        </i>
        <a href="mailto:support@idm-energie.at">support@idm-energie.at</a>
      </p>
    </div>
  </div>
</template>

<script>
import { HelpFilter, HelpSystem } from "./content/";

export default {
  name: "Help",
  components: {
    HelpFilter,
    HelpSystem
  },
  computed: {
    mode() {
      return this.$store.state.app.mode;
    },    
    showDetail() {
      return this.$store.state.app.showDetail;
    }
  },
  created() {
  },
  data() {
    return {
      detailItem: null,
    }
  },
  props: {
    filters: {
      type: Array,
      default: null
    },
    systemhelp: {
      type: Array,
      default: null
    },
    show: {
      type: Boolean,
      default: false
    },
    displayStyle: {
      type: String,
      default: ""
    },
    modal: {
      type: Boolean,
      default: false
    }
  },  
  watch: {
    show: function() {
      if (this.modal) {
        const wrapper = document.querySelector('.configurator-result');
        if (wrapper) {
          if (this.show) wrapper.classList.add('has-popup')
          else wrapper.classList.remove('has-popup');
        }
        const container = document.querySelector('.result-container');
        if (container) {
          if (this.show) container.classList.add('has-popup')
          else container.classList.remove('has-popup');
        }
      } 
    }
  },
  methods: {
    onToggleHelp() {
      console.log('Help: onToggleHelp');
      this.$emit('onToggleHelp');
    }
    
    //  const wrapper = document.querySelector('.app-wrapper');
    // if (wrapper) wrapper.classList.add('has-popup');
  }
};
</script>
