<template>
  <div class="form-property" @transitionend="onTransitionend" :class="{ 'is-folding': folding, 'is-disabled': disabled }" :style="style" >
    <div class="property-inner" ref="domInner">
      <p class="form-property__label">{{ item.label }} <Quickinfo v-if="item.quickinfo" :item="item"/></p>
      <div class="check-group" :class="classes">
        <div class="check-group__item"
          v-for="(option, idx) in item.options"
          :key="'option_' + option.uid"
          :class="{ 'is-selected': values[idx] || storedValue.value[idx], 'is-hidden': !option.show }"
        >
          <input 
            type="checkbox" 
            v-model="values[idx]" 
            :name="item.name + '_' + option.value"
            :id="item.name + '_' + option.value"
            :value="option.value"
            @change="onChange($event, item)"
            :class="{ 'is-selected': values[idx] || storedValue.value[idx]}"
          />
          <label :for="item.name + '_' + option.value" >
            <span class="check">
              <i class="svgicon svgicon--check">
                <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 6.5L6 11.5L16.5 1" stroke="#171F27" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
              </i>
            </span>
            {{ option.text }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { ConfiguratorFormController } from "@/controller";
import { Quickinfo } from "@/components/";

export default {
  name: "CheckGroup",
  components: {
    Quickinfo
  },  
  computed: {
    multiple() {
      return this.item.type.indexOf("multiple")!=-1;
    },
    direction() {
      return "vertical";
    },
    classes() {
      return "check-group--" + this.direction + (this.multiple?" check-group--multiple":"") +  (this.disabled?" is-disabled ":"");
    },
    style() {
      //const height = this.foldingHeight == "auto" ? "auto" : this.foldingHeight + "px;";
      //return "height: " + height;
      return "height: auto;";
    }
  },
  props: {
      handles: {
          type: Array,
          default: null
      },
      item: {
          type: Object,
          default: null
      },
      storedValue: {
        type: Object,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      }
  },
  data() {
    return {
      values: [],
      options: [],
      folding: false,
      foldingHeight: "auto"      
    }
  },
  watch: {
    storedValue: {
      handler: function() {
        this.storedValue.value.forEach((val, idx) => {
          this.values[idx] = val;
        });
      }
    } 
  },
  created() {
    if (this.item) {
      if (this.multiple) {
        this.storedValue.value.forEach(val => {
          this.values.push(val);
        });
      }
    /* if (this.item.isConditional) {
      this.folding = true;
      this.foldingHeight = 0;
    }   */    
    }
    //console.log('item.options, storedValues', (this.item?this.item.options:""), this.storedValue);
  },
  mounted() {
    if (this.item && this.item.isConditional) {
      this.foldingHeight = this.$refs.domInner.offsetHeight;
    }
  },
  methods: {
    onChange(ev, item) {
      const selected = item.options.filter((item, idx) => item.show && this.values[idx]).map(e => e.value);
      console.log("CheckGroup:onChange:selected", this.values, item.options, selected);
      this.$emit("onChange", ev, item, selected);
    },
    onTransitionend() {
      /* this.folding = false;
      this.foldingHeight = "auto"; */
    },    
    /* getOptionsToShow(item) {
      return ConfiguratorFormController.getOptionsToShow(item);
    }, */
  },
};
</script>