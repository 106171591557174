<template>
  <div 
    class="cardlist__subitem" 
    :class="{ 'has-image': item.image, 'has-linked': item.subcards, 'is-linked': parent, 'is-expanded': expanded, 'is-always-expanded': (itemIndex == 0 && alwaysExpanded), 'is-always-collapsed': ((itemIndex != 0 && alwaysExpanded) || (parent && alwaysExpanded)), 'is-collapsed': collapsed, 'has-transition': cardTransition, 'is-notused': item.notused }" 
    @click="handleClick"
    :style="cardStyle"
    ref="domCard"
    @transitionend="onCardTransitionEnd"
  >
    <div class="cardlistitem__content" ref="domCardContent">
      <div class="card__permanent">
        <Cardimage v-if="item.image" :image="item.image" :title="item.headline" />
        <figure class="image" v-if="item.notused">
          <i class="svgicon svgicon--nothingselected" v-html="iconNothingSelected"></i>
        </figure>
        <Carddots v-if="mode=='expert' && !item.notused" :dotValue="item.dots" :itemId="item.uniqeId" />
        <header ref="domItemHeader">
          <h3 class="headline" ref="domItemHeadline">{{ item.headline }}</h3>
          <h4 class="subline">{{ itemSubline }}</h4>
          <div class="taglist" v-if="!item.notused">
            <Cardtag 
              v-for="(tag, idx) in item.tags"
              :key="item.uniqeId + '_' + idx"
              :tag="tag"
            /> 
          </div>
        </header>
        <div class="icon">
          <Cardicon v-if="item.icon" :icon="item.icon" />
        </div>
        <button class="button--collapse-expand button--flat noslide" v-if="(itemIndex == 0 && !parent)" @click.stop="handleToggleClick">
          <i class="svgicon svgicon--chevrondown">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.41 8.59L12 13.17L16.59 8.59L18 10L12 16L6 10L7.41 8.59Z" fill="white"/>
            </svg>
          </i>
        </button>
      </div>
      <div class="card__flexible" :style="flexibleStyle">
        <div class="flexible__inner" ref="domFlexibleInner">
          <ul v-if="item.focus_texts" class="factlist">
            <li 
              v-for="fact in item.focus_texts"
              :key="fact.uniqeId"
              v-bind="fact"
            >
              <span class="label">{{ fact.description }}</span>
              <span v-if="showValue(fact)" class="value">{{ fact.value }}</span>
              <span class="icon" v-if="fact.icon"><Cardicon :icon="fact.icon" /></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Cardtag from "./Cardtag.vue";
import Cardimage from "./Cardimage.vue";
import Cardicon from "./Cardicon.vue";
import Carddots from "./Carddots.vue";

export default {
  name: "Card",
  components: {
    Cardtag,
    Cardimage,
    Cardicon,
    Carddots
  },
  computed: {
    itemSubline() {
      if ( Array.isArray(this.item.subline)) {
        return this.item.subline.join(" ");
      } else {
        return this.item.subline;
      }
    },
    flexibleStyle() {
      let h = this.heights.flexibleInner; 
      return h!=-1?"height: " + h + "px;":"";
    },
  },
  data() {
    return {
      items: [],
      heights: {
        card: -1,
        flexibleInner: -1
      },
      cardTransition: false,
      expanded: false,
      collapsed: false,
      cardStyle: "",
      iconNothingSelected: "<svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M42 24V38C42 39.0609 41.5786 40.0783 40.8284 40.8284C40.0783 41.5786 39.0609 42 38 42H10C8.93913 42 7.92172 41.5786 7.17157 40.8284C6.42143 40.0783 6 39.0609 6 38V10C6 8.93913 6.42143 7.92172 7.17157 7.17157C7.92172 6.42143 8.93913 6 10 6H32' stroke='#8197AC' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/><path d='M30 18L18 30' stroke='#8197AC' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/><path d='M18 18L30 30' stroke='#8197AC' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>"
    }
  },
  watch: {
    /* collapsed() {
      if (this.collapsed) this.onCollapse();
    },
    expanded() {
      if (this.expanded) this.onExpand();
    }, */
    mainPumpFocusOn() {
      if (this.mainPumpFocusOn && this.mainPumpCard) {
        this.expand();
      } else if (this.mainPumpFocusOn) {
        this.collapse();
      } else {
        this.reset();
      }
    }
  },
  props: {
      mode: {
        type: String,
        default: ""
      },
      item: {
        type: Object,
        default: null
      },
      parent: {
        type: Object,
        default: null
      },
      mainPumpFocusOn: {
        type: Boolean,
        default: false
      },
      mainPumpCard: {
        type: Boolean,
        default: false
      },
      itemIndex: {
        type: Number,
        default: 0
      },
      alwaysExpanded: {
        type: Boolean,
        default: false
      }
  },
  created() {

  },
  methods: {
    handleClick() {
      //console.log('card handleClick', ev.target);
      //this.$emit("onClick", ev);
    },
    reset() {
      let h;
      if (this.expanded) {
        this.expanded = false;
        h = this.getHeaderHeight() + this.$refs.domFlexibleInner.getBoundingClientRect().height + this.getCardPaddingHeight();
      } else if (this.collapsed) {
        this.collapsed = false;
        h = this.getHeaderMinHeight();
      }
      this.cardStyle = "height: " + h + "px;";
      this.cardTransition = true;
      this.$nextTick(() => {
        //console.log('reset height to: ', this.getHeaderHeight(), this.getCardPaddingHeight());
        h = (this.getHeaderHeight() + this.getCardPaddingHeight());
        this.cardStyle = "height: " + h + "px;";
      });
    },
    onCardTransitionEnd() {
      this.cardTransition = false;
    },
    getHeaderHeight() {
      return this.$refs.domItemHeader?this.$refs.domItemHeader.getBoundingClientRect().height:0;
    },
    getHeaderMinHeight() {
        let h = (this.$refs.domItemHeadline?this.$refs.domItemHeadline.getBoundingClientRect().height:0) + this.getCardPaddingHeight();
        //console.log('getHeaderMinHeight:', (this.$refs.domItemHeadline?this.$refs.domItemHeadline.getBoundingClientRect().height:0), this.getCardPaddingHeight());
        return h;
    },
    getContentMinHeight() {
      let h = this.$refs.domCardContent?this.$refs.domCardContent.getBoundingClientRect().height:-1 + this.getCardPaddingHeight();
      return h;
    },
    getFlexibleHeight() {
      return this.$refs.domFlexibleInner?this.$refs.domFlexibleInner.getBoundingClientRect().height:0;
    },
    getCardPaddingHeight() {
      if (this.$refs.domCard) {
        const cStyle = window.getComputedStyle(this.$refs.domCard);
        //console.log('getCardPaddingHeight', parseInt(cStyle["padding-bottom"]), this.collapsed);
        if (this.collapsed) {
          return 32; //padding top and bottom at 16px
        } else {
          return (cStyle["padding-top"]?parseInt(cStyle["padding-top"]):0) + (cStyle["padding-bottom"]?parseInt(cStyle["padding-bottom"]):0);
        }
      } else {
        return 0;
      }
    },
    collapse() {
      this.cardStyle = "height: " + this.$refs.domCardContent.getBoundingClientRect().height + "px;";
      this.cardTransition = true;
      this.$nextTick(() => {
        this.collapsed = true;
        this.cardStyle = "height: " + this.getHeaderMinHeight() + "px;";
        this.expanded = false;
      });
    },
    expand() {
      this.cardStyle = "height: " + (this.getHeaderHeight() + this.getCardPaddingHeight()) + "px;";
      this.cardTransition = true;
      this.expanded = true;
      this.$nextTick(() => {
        let h = this.getHeaderHeight() + this.$refs.domFlexibleInner.getBoundingClientRect().height + this.getCardPaddingHeight();
        this.cardStyle = "height: " + h + "px;";
      });
    },    
    handleToggleClick() {
      this.$emit("toggleClick", this.item);
      /* if (this.expanded) {
        this.collapse();
      } else {
        this.expand();
      } */
    },
    showValue(fact) {
      const noicon = ["check", "cross", "a+++", "a++", "a+", "a", "b", "c", "d"];
      return !fact.icon || noicon.indexOf(fact.icon.toLowerCase()) == -1;
    }

  },
};
</script>
