<template>
    <div class="detail-footer">
      <div class="footer__inner">
        <div class="footer-description" v-if="page && page.footer" v-html="page.footer">
          <!-- <p v-if="mode=='expert'">Gebäude Heizlastberechnung nach DIN EN 12831 (Vereinfachtes Verfahren). Warmwasser Energiebedarf nach VDI 4645</p> -->
          <!-- <p v-if="page && page.footer" v-html="page.footer"></p> -->
        </div>
        <div class="footer-buttons">
          <a v-if="mode && mode=='expert' && item && item.scheme" :href="getDownloadSchemeSource()" class="button button--download button--download-scheme" target="_blank">{{ getContent('CONF_TEXT_BUTTON_HYDRAULIC_SCHEME', true) }}<i class="svgicon svgicon--download" v-html="icons.download"></i></a>

          <a class="button button--download button--download-pdf" v-if="item && item.project_pdf" :href="getDownloadPdfSource()" target="_blank">{{ getContent('CONF_TEXT_BUTTON_PDF_DOWNLOAD', true) }}
            <i class="svgicon svgicon--download" v-html="icons.download"></i>
          </a>

          <button v-if="mode && mode!='expert' && item" class="button button--primary button--request" @click="handleShowRequest">{{ getContent('TEXT_NONBINDING_REQUEST', true) }}</button>
        </div>
      </div>
    </div>
</template>

<script>
import { PdfController, ContentController, ConfiguratorResultController /*, DownloadController */ } from "@/controller";
import { EventBus } from "@/bus/eventbus.js";

export default {
  name: "DetailFooter",
  components: {
  },
  computed: {
  },
  data() {
    return {
      icons: {
        download: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 9H15V3H9V9H5L12 16L19 9ZM5 18V20H19V18H5Z" fill="#CDDDEC"/></svg>',
        loaderSVG: '<svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff"><g fill="none" fill-rule="evenodd"><g transform="translate(1 1)" stroke-width="2"><circle stroke-opacity=".5" cx="18" cy="18" r="18"/><path d="M36 18c0-9.94-8.06-18-18-18"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/></path></g></g></svg>',
      },
      page: null,
      creatingProject: false,
      projectPdf: {}
    }
  },
  watch: {
    item() {
      //console.log('watch item', this.item);
    },
    mode() {
      //console.log('watch mode', this.mode);
    },
    projectPdf() {
    }
  },
  props: {
      item: {
        type: Object,
        default: null
      },
      mode: {
        type: String,
        default: ""
      },
  },
  created() {
    EventBus.$on("onPageChange", page => {
      //console.log('Detail onPageChange', page);
      this.page = page;
    });    
    EventBus.$on("onPdfGenerated", () => {
      this.projectPdf.pdf = PdfController.pdf;
      this.projectPdf.pdfFilename = PdfController.pdfFilename;
      //console.log("DetailFooter:onPdfGenerated", this.projectPdf.pdf);
      if (this.projectPdf.pdf) this.creatingProject = false;
    });
  },
  mounted() {
    //console.log('DetailFooter mounted', this.item);
  },
  methods: {
    getDownloadSchemeSource() {
      return PdfController.getDownloadSchemeSource(this.item.scheme);
    },
    getDownloadPdfSource() {
      const centPerKwh = ConfiguratorResultController.getCentPerKwh();
      return PdfController.getDownloadPdfSource(this.item.project_pdf + "?cent_per_kwh=" + centPerKwh);
    },
    handleShowRequest() {
      EventBus.$emit("onShowRequest");
    },
    /* handleProjectPdf() {
      if (!PdfController.pdf) {
        PdfController.directDownload = true;
        EventBus.$emit("onCreateProjectPdf");
        this.creatingProject = true;
      } else {
          const downloadLink = document.createElement("a");
          downloadLink.href = this.projectPdf.pdf;
          //downloadLink.target = "_blank";
          downloadLink.download = this.projectPdf.pdfFilename;
          downloadLink.click();
          //window.open(this.projectPdf.pdf, "_blank").focus();
      }
    }, */
    getContent(content, clean) {
      return ContentController.getContent(content, clean);
    }      
  },
};
</script>


