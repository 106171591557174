<template>
  <figure class="image">
    <img :src="imageSource" :alt="title" :title="title"/>
  </figure>
</template>

<script>

import { ImageController } from "@/controller";

export default {
  name: "Cardimage",
  components: {
  },
  computed: {
    imageSource() {
      const src = ImageController.getCardImageSrc(this.image);
      //console.log('Cardimage src', src);
      return src;
    }
  },
  data() {
    return {}
  },
  watch: {
  },
  props: {
      image: {
        type: Object,
        default: null
      },
      title: {
        type: String,
        default: ""
      }
  },
  created() {
  },
  methods: {
  },
};
</script>
