<template>
  <div class="configurator-result" :class="{ 'is-expert': mode=='expert', 'is-loading': !serverResponseReceived}">

    <div class="result-loading-wrapper" >
        <div class="cardloading-list">
          <CardSkeleton 
            v-for="item in loadingCards" 
            v-bind:key="'loadingCard_'+item"
          />
        </div>
    </div>

    <div class="result-header" :class="{ 'result-header-empty': this.items && this.items.length == 0 }">
      <p>{{ message && message.length ? message : getContent('TEXT_SYSTEMS_LOADING', true) }}</p>
      <!-- <p v-if="this.items && this.items.length">iDM Energiesysteme empfiehlt dir folgende Wärmepumpensysteme:</p>  --><!-- TODO: hardcoded -->
      <!-- <p v-if="this.items && this.items.length == 0">Es wurden keine passenden Systeme gefunden</p>  --><!-- TODO: hardcoded -->
      <MobileHelpToggle />
    </div>
   
    <div class="result-content">

      <Help :systemhelp="systemhelp" class="help-system" :show="systemHelpActive" :displayStyle="systemHelpStyle" @onToggleHelp="systemHelpActive = !systemHelpActive" />

      <div class="cardlist-container" ref="domCardContainer" :class="{ 'has-smooth-scrolling': !slide.move, 'is-moving': slide.move, 'is-help-active': systemHelpActive }" :data-x="slide.x" @mousedown="handleMouseDown" @mousemove="handleMouseMove" @mouseup="handleMouseUp" @mouseleave="handleMouseLeave">
        <div class="cardlist" ref="domCardList" >
          <div class="cardlist__item"
            v-for="(item, cardidx) in items"
            :key="item.uniqeId"
            :data-index="cardidx"
          >
            <div class="cardlist-item__inner">
              <div class="cardinfo">
                <p class="cardinfo__label">
                  <span>{{ getContent('CONF_TEXT_HEADLINE_CARD', true) }} {{ cardidx+1 }}</span>
                </p>
              </div>
              <div class="cardlist__itemlist">
                <div v-for="(subitem, idx) in item.pages[0].summary"
                  :key="subitem.uniqeId + '_' + idx"
                >
                    <Card
                      :key="subitem.uniqeId"
                      :data-index="idx"
                      :itemIndex="idx"
                      :item="subitem"
                      :mainPumpFocusOn="isMainPumpExpanded(item.uniqeId)"
                      :mode="mode"
                      :mainPumpCard="idx == 0"
                      @toggleClick="toggleMainPumpExpand(item.uniqeId)"
                    />
                    <Card 
                      v-for="(linkedcard, subidx) in subitem.subcards"
                      :key="linkedcard.uniqeId"
                      :data-index="idx"
                      :itemIndex="idx"
                      :data-linked-index="subidx"
                      :item="linkedcard"
                      :parent="subitem"
                      :mainPumpFocusOn="isMainPumpExpanded(item.uniqeId)"
                      :mode="mode"
                      :cardIndex="false"
                    />
                </div>
              </div>
              <div class="cardlistitem__action">
                <button class="button--primary" @click.stop="handleDetailClick(item)">{{ getContent('CONF_TEXT_CHOOSE_SYSTEM', true) }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cardlist-slide cardlist-slide--prev" :class="{ 'is-active': swipePrevPossible }" ref="domCardListSlidePrev">
        <button class="button--secondary" @click="handleSlideClick('prev')" :title="getContent('CONF_TEXT_BUTTON_FURTHER', true)">
          <i class="svgicon svgicon--chevron">
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.59 1.41L5.17 6L0.59 10.59L2 12L8 6L2 -2.62268e-07L0.59 1.41Z" fill="white"/>
            </svg>
          </i>
        </button>    
      </div>
      <div class="cardlist-slide cardlist-slide--next" :class="{ 'is-active': swipeNextPossible }" ref="domCardListSlideNext">
        <button class="button--secondary" @click="handleSlideClick('next')" :title="getContent('CONF_TEXT_BUTTON_BACK', true)">
          <i class="svgicon svgicon--chevron">
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.59 1.41L5.17 6L0.59 10.59L2 12L8 6L2 -2.62268e-07L0.59 1.41Z" fill="white"/>
            </svg>
          </i>
        </button>    
      </div>
    </div>
  </div>
</template>

<script>

import { ConfiguratorResultController, ContentController } from "@/controller";
import { Card, CardSkeleton } from "./list";
import { EventBus } from "@/bus/eventbus.js";
import { Help } from "./help";
import { MobileHelpToggle } from "@/views/layout/components";


export default {
  name: "ConfiguratorResult",
  components: {
    Card,
    CardSkeleton,
    Help,
    MobileHelpToggle
  },
  computed: {
    smoothScrolling() {
      return !this.slide.move;
    },
    systemHelpStyle() {
      const style = (this.cardListHeight ? "height: " + this.cardListHeight + "px;" : "");
      return style;
    }
  },
  data() {
    return {
      items: [],
      systemhelp: [],
      message: [],
      //expanded: [],
      usersChoice: -1, //no longer desired
      systemHelpActive: false,
      mainPumpFocusOn: false,
      swipeNextPossible: true,
      swipePrevPossible: false,
      mainPumpsExpanded: [],
      slide: {
        x: 0,
        startX: 0,
        move: 0,
        moveX: 0,
        intransition: false
      },
      loadingCards: [1, 2, 3, 4, 5],
      cardListHeight: 0,
      serverResponseReceived: false
    }
  },
  watch: {
    mode() {
        //do nothing, do this with updateResult
      //this.resetResult();
    },
    filters() {
      console.log("watch filter", this.filters);
    }
  },
  props: {
      mode: {
        type: String,
        default: ""
      }
  },
  created() {
    EventBus.$on("onResultFilterChanged", () => {
      this.filterChanged();
    });
    //EventBus.$on("onUpdateResult", () => this.getResultData());
    EventBus.$on("onConfigurationFormResponseReceived", response => {
      console.log('ConfiguratorResult -> onConfigurationFormResponseReceived', response);
      this.serverResponseReceived = true;
      ConfiguratorResultController.setItems(response.data.data?response.data.data:response.data);
      ConfiguratorResultController.setSystemHelp(response.data);
      ConfiguratorResultController.setMessage(response.data);
      this.items = ConfiguratorResultController.items;
      this.filters = ConfiguratorResultController.filterItems;
      this.systemhelp = ConfiguratorResultController.systemHelp;
      this.message = ConfiguratorResultController.message;
        //everything setup correctly -> emit
      EventBus.$emit("onSetupConfiguratorFilterAndHelp");
      this.$nextTick(() => {
        this.cardListHeight = this.$refs.domCardList.getBoundingClientRect().height;
      });
    });
    EventBus.$on("onBeforeUpdateResult", () => {
      this.serverResponseReceived = false;
      ConfiguratorResultController.resetItems();
      this.items = [];
    });
    EventBus.$on("doResize", () => {
      this.$nextTick(() => {
        this.cardListHeight = (this.$refs && this.$refs.domCardList ? this.$refs.domCardList.getBoundingClientRect().height : 0);
      });
    });
      //[2022-06-30] items are property and given form configuratorform post
    //this.getResultData();
  },
  methods: {
    itemsLoaded() {
      console.log('request itemsLoaded: ', this.items && this.items.length);
      return this.serverResponseReceived;
    },
    async getResultData(/* modeChanged */) {
      this.items = await ConfiguratorResultController.getData(/* modeChanged */);
      //console.log("ConfiguratorResult[getResultData] this.items", this.items);
      //this.buildExpandedEntries();
    },
    /* buildExpandedEntries() {
      this.items.forEach(() => this.expanded.push({
        index: -1,
        linkedIndex: -1
      }));
    }, */
    /* setExpanded(cardidx, idx, subidx) {
      if (this.expanded[cardidx]) this.expanded[cardidx].index = idx;
      if (this.expanded[cardidx]) this.expanded[cardidx].linkedIndex = subidx;
    }, */
   /* getCardExpanded(cardidx, idx) {
      return this.expanded[cardidx] && (this.expanded[cardidx].index==idx && this.expanded[cardidx].linkedIndex==-1);
    },
    getCardCollapsed(cardidx, idx) {
      return this.expanded[cardidx] && ((this.expanded[cardidx].index!=-1 && this.expanded[cardidx].index!=idx) || this.expanded[cardidx].linkedIndex != -1);
    }, 
    getLinkedCardExpanded(cardidx, idx, subidx) {
      return this.expanded[cardidx] && (this.expanded[cardidx].index==idx && this.expanded[cardidx].linkedIndex==subidx);
    },
    getLinkdedCardCollapsed(cardidx, idx, subidx) {
      return this.expanded[cardidx] && ((this.expanded[cardidx].index != -1 && this.expanded[cardidx].linkedIndex == -1) || (this.expanded[cardidx].linkedIndex != -1 && this.expanded[cardidx].linkedIndex != subidx));
    }, */
    filterChanged() {
      this.items = ConfiguratorResultController.getFilteredItems();
      console.log('ConfiguratorResult[filterChanged] this.items', this.items);
    },
    /* resetResult() {
      //just clear data, let the rest be done within in the formcontroller / form
      console.log("ConfiguratorResult[reset]", this.mode);
      this.items = [];
    }, */
    handleDetailClick(item) {
      this.$emit("onItemDetailClick", item);
    },
    handleMainpumpFocusChanged(value) {
      this.mainPumpFocusOn = value;
    },
    isMainPumpExpanded(uniqeId) {
      return this.mainPumpsExpanded.indexOf(uniqeId) != -1;
    },
    toggleMainPumpExpand(uniqeId) {
      const idx = this.mainPumpsExpanded.indexOf(uniqeId);
      if (idx != -1) {
        this.mainPumpsExpanded.splice(idx,1);
      } else {
        this.mainPumpsExpanded.push(uniqeId);
      }
    },

    handleSlideClick(direction) {
      if (direction == "next") {
        this.cardSlideNext();
      } else if (direction == "prev") {
        this.cardSlidePrev();
      }
    },

    handleMouseDown(ev) {
      if (ev.buttons == 1) {
        this.moveStart(ev.pageX);
      }
    },

    handleMouseMove(ev) {
      if (this.slide.move) {
          this.move(ev.pageX);
      }
      ev.preventDefault();
    },

    handleMouseUp(ev) {
      if (this.slide.move) this.moveEnd(ev, ev.pageX);
    },

    handleMouseLeave(ev) {
      if (this.slide.move) this.moveEnd(ev, ev.pageX);
    },

    moveStart(pageX) {
      const container = this.$refs.domCardContainer;
      if (container) {
        const parentBounding = container.parentNode.getBoundingClientRect();
        this.slide.mouseX = pageX - parentBounding.left;
        this.slide.startX = pageX - parentBounding.left;
        this.slide.move = 1;
      }
    },

    moveEnd(ev, pageX) {
      const container = this.$refs.domCardContainer;
      if (container) {
        const parentBounding = container.parentNode.getBoundingClientRect();
        const delta = parseFloat(this.slide.startX) - (pageX - parentBounding.left);
        this.slide.move = 0;
        this.slide.mouseX = 0;
          //not needed anymore - the button click works
        /* if (delta > -5 && delta < 5) {
            //const link = ev.target.querySelector('a');
            if (link) {
                link.click();
            } else {
                const cPath = ev.composedPath().filter(el => el && el.classList && el.classList.contains('noslide'));
                if (!cPath.length) ev.target.click();
            }
        } */

        if (delta < -20) {
            //console.log('[WebWebScrollTo] move to left');
            //site.cardSlideLeft(slider);
        } else if (delta > 20) {
            //console.log('[WebWebScrollTo] move to right');
            //site.cardSlideRight(slider);
        }

        this.checkSlideLinksActive(container)
      }
    },    

    move(pageX) {
      const container = this.$refs.domCardContainer;
      if (container) {
        const parentBounding = container.parentNode.getBoundingClientRect();
        //const containerBounding = container.getBoundingClientRect();
        let delta = (this.slide.mouseX - (pageX - parentBounding.left)) *-1; //*-1 change direction is more intuitive

        /* if (delta < 0 && delta > -5)  {
          delta = -5;
        } else if (delta > 0 && delta < 5) {
          delta = 5;
        } */
        const maxScrollLeft = container.scrollWidth - container.clientWidth;

        let x = this.slide.x - delta;
        if (x > maxScrollLeft) {
            x = maxScrollLeft;
        }
        if (x < 0) {
          x = 0;
        }
        
        container.scrollLeft = x;
        this.slide.x = x;
        this.slide.mouseX = (pageX - parentBounding.left);
      }
    },  

    cardSlidePrev() {
      const container = this.$refs.domCardContainer;
      if (container) {
        const items = Array.from(container.firstElementChild.children);
        //const fItem = container.firstElementChild.firstElementChild;
        const bounding = items[0].getBoundingClientRect();
        let sLeft = container.scrollLeft; //(container.scrollLeft >= (container.scrollWidth - container.clientWidth) - container.clientWidth)?container.scrollLeft - container.clientWidth:container.scrollLeft;
        let index = Math.floor(sLeft / bounding.width);
        if (sLeft < bounding.width) {
            index = 0;
        } else {
            index--;
        }
        let x = items[index].offsetLeft;
        container.scrollLeft = x;
        this.slide.x = x;
        setTimeout(() => this.checkSlideLinksActive(container), 200);
      }
    },
                  

    cardSlideNext() {
      const container = this.$refs.domCardContainer;
      if (container) {
        const items = Array.from(container.firstElementChild.children);
        const bounding = items[0].getBoundingClientRect();
        if (parseInt(container.scrollLeft / bounding.width) < items.length-1) {
            const itemsPerPage = Math.floor(container.parentNode.getBoundingClientRect().width / bounding.width);
            let index = Math.floor(parseInt(container.scrollLeft) / bounding.width);
            if (index < items.length - itemsPerPage) {
                index++;
            } else {
                index = items.length - itemsPerPage;
                container.scrollLeft = 0;
            }
            let x = items[index].offsetLeft; //index*bounding.width;
            if (index == (items.length - itemsPerPage)) {
                //maxScrollLeft
              x = container.scrollWidth - container.clientWidth;
            }
            container.scrollLeft = x;
            this.slide.x = x;
            setTimeout(() => this.checkSlideLinksActive(container), 200);
        }
      }
    },

    checkSlideLinksActive(container) {
        //const fItem = container.firstElementChild.firstElementChild;
        //const itemWidth = (fItem?fItem.getBoundingClientRect().width:0);
            //TODO: code TRANSLATE
        const nextSlide = this.$refs.domCardListSlideNext; //dragslide.querySelector('.dragslide-slide--next') || dragslide.parentNode.querySelector('.dragslide-slide--next');
        const prevSlide = this.$refs.domCardListSlidePrev; //dragslide.querySelector('.dragslide-slide--prev') || dragslide.parentNode.querySelector('.dragslide-slide--prev');
       
        if (prevSlide && (container.scrollLeft > 0)) {
          this.swipePrevPossible = true;
        } else if (prevSlide) {
          this.swipePrevPossible = false;
        }

            //TODO: code TRANSLATE
        const maxScrollLeft = container.scrollWidth - container.clientWidth;
        if (nextSlide && maxScrollLeft <= parseInt(container.scrollLeft)) {
          this.swipeNextPossible = false;
        } else if (nextSlide) {
          this.swipeNextPossible = true;
        }

    },

    getContent(content, clean) {
      return ContentController.getContent(content, clean);
    }     

  },
};
</script>
