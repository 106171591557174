<template>
  <div class="form-property" :class="{ 'has-error': hasError }">

    <label for="googlePlacesAutocomplete">{{ getContent('CONF_TEXT_VAR_LOCATION', true) }}</label>
    <p class="form-error" v-show="hasError" v-html="errorMsg"></p>
    <input type="text" id="googlePlacesAutocomplete" ref="domGooglePlacesAutocomplete" v-model="placesSearch" name="placesSearch" autocomplete="off" @change="onChange" @keydown="onKeyDown" />  
    <ul class="google-predictions" :class="{ 'is-active': listActive }">
      <li v-for="(result, i) in searchResults" :key="i" @click="onSearchResultClicked(result)">
        {{ result.description }}
      </li>
    </ul>
    
  </div>
</template>

<script>
import { EventBus } from "@/bus/eventbus.js";
import { GooglePlacesController, ContentController } from "@/controller";
import Store from "@/store";
//import { Quickinfo } from "@/components/";
import GoogleMapsCallback from "@/utils/GoogleMapsCallback.js";

export default {
  name: "GoogleAutoComplete",
  components: {
    //Quickinfo
  },  
  computed: {
  },
  props: {
      handles: {
          type: Array,
          default: null
      },
      item: {
          type: Object,
          default: null
      },
      storedValue: {
        type: String,
        default: null
      },
  },
  data() {
    return {
      value: "",
      placesSearch: this.storedValue,
      searchResults: [],
      service: null,
      listActive: false,
      doSearch: true,
      hasError: false,
      errorMsg: "Fehler bei der Eingabe"
    }
  },
  watch: {
    placesSearch(newValue) {
      if (this.doSearch && newValue) {
        this.service.getPlacePredictions({
          input: this.placesSearch,
          componentRestrictions: { country: ["at", "de"] },
          types: ["(regions)"]
        }, this.displaySuggestions)
        if (document.activeElement === this.$refs.domGooglePlacesAutocomplete) {
          this.listActive = true;
        }
      }
        //reactivate Search
      this.doSearch = true;
    },
    storedValue(newValue) {
      this.placesSearch = newValue;
    },
  },  
  metaInfo() {
    return {
      script: Store.state.app.googlePlacesLoaded?[]:[{
        src: "https://maps.googleapis.com/maps/api/js?key=" + GooglePlacesController.apiKey + "&libraries=places&callback=window.googleMapCallback",
        async: true,
        defer: true,
        callback: () => this.MapsInit() // will declare it in methods
      }]
    }
  },  
  created() {
    window.googleMapCallback = GoogleMapsCallback;
    if (Store.state.app.googlePlacesLoaded) this.MapsInit();
    EventBus.$on("onFormError", () => this.checkError());
  },
  methods: {
    MapsInit() {
      Store.dispatch("setGooglePlacesLoaded", true);
      this.service = new window.google.maps.places.AutocompleteService(document.createElement('div'));
    },
    getDetailsCallback(placeDetail) {
      //console.log("getDetailsCallback", placeDetail);
      const country = placeDetail.address_components.filter(adr => adr.types[0] == "country" );
      //console.log("detail.getlang", country, placeDetail.geometry.location.lat(), placeDetail.geometry.location.lng());
      this.$emit("onChange", (country && country.length)?country[0].short_name:"", placeDetail.geometry.location.lat(), placeDetail.geometry.location.lng(), placeDetail.formatted_address);
      this.doSearch = false;
      this.placesSearch = placeDetail.formatted_address;
      this.checkError();
    },
    displaySuggestions(predictions, status) {
      //console.log("displySuggestions", this.service);
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = []
        return
      }
      //console.log("predictions", predictions);
      this.searchResults = predictions; //.map(prediction => prediction.description);
    },
    onSearchResultClicked(place) {
      if (!this.detailService || typeof this.detailService.getDetails === "undefined") {
        this.detailService = new window.google.maps.places.PlacesService(document.createElement('div'));
      }

      this.detailService.getDetails({
        placeId: place.place_id, 
        fields: ["name", "address_component", "geometry", "formatted_address"]
      }, this.getDetailsCallback);
      this.listActive = false;
    },
    onChange(ev) {
      if (ev.target.value == "") this.$emit("onReset");
      //this.$emit("onChange", ev, item, this.value);
    },
    onKeyDown(ev) {
      const key = ev.charCode || ev.keyCode || 0;
      if (key == 13) {
        ev.preventDefault();
      }
    },
    checkError() {
      if (!this.placesSearch) {
        this.hasError = true;
        this.errorMsg = ContentController.getContent("ERROR_MSG_NOLOCATION");
      } else {
        this.hasError = false;
      }
      this.$emit("onErrorChange", "placesSearch", this.hasError);
    },
    getContent(content, clean) {
      return ContentController.getContent(content, clean);
    }       
  },
};

</script>