<template>
  <div class="form-property form-property--switch" :class="{ 'is-active': value, 'is-disabled': disabled }">
    <p class="form-property__label">{{ item.label }} <Quickinfo v-if="item.quickinfo" :item="item"/></p>
    <label class="form-property__label" :for="'input' + item.name" @click="onChange($event, item)" :class="{ 'is-disabled': disabled }">
      <input type="checkbox" :name="'input' + item.name" :id="elName" v-model="value" @change="onChange($event, item)" :class="{ 'is-selected': value }" />
      <span v-if="false" class="label label__off">{{ getContent('TEXT_OFF', true) }}</span>
      <span class="switch">
        <span class="switch__line"></span>
        <span class="switch__dot" @transitionend="onTransitionend"></span>
      </span>
      <span v-if="false" class="label label__on">{{ getContent('TEXT_ON', true) }}</span>
    </label>
  </div>
</template>

<script>
import Uniqe from "@/utils/Uniqe.js";
import { Quickinfo } from "@/components/";
import { ContentController } from "@/controller";

export default {
  name: "BoolSwitch",
  components: { 
    Quickinfo
   },
  computed: {
  },
  props: {
      handles: {
        type: Array,
        default: null
      },
      item: {
        type: Object,
        default: null
      },
      storedValue: {
        type: Object,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      }
  },
  data() {
    return {
      value: this.storedValue.value,
      elName: Uniqe.getUniqe()
    }
  },
  watch: {
    storedValue: function() {
      this.value = this.storedValue.value;
    }
  },
  created() {
  },
  methods: {
    onChange() {
      if (!this.disabled) this.value = !this.value;
    },
    onTransitionend(ev) {
        //TODO: check if works in every case
      this.$emit("onChange", ev, this.item, this.value);
    },
    getContent(content, clean) {
      return ContentController.getContent(content, clean);
    }      
  },
};
</script>