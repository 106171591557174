<template>
  <div class="section__item" @transitionend="onTransitionend" :class="{ 'is-active': isActive, 'is-collapsible': isCollapsible }">
    <p class="section__permanent section__title" :class="{ 'is-collapsible': isCollapsible }" ref="domSectionPermanent" @click="handleClick">
      <i class="svgicon svgicon--chevrondown" v-if="isCollapsible">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.41 8.59L12 13.17L16.59 8.59L18 10L12 16L6 10L7.41 8.59Z" fill="white"/>
        </svg>
      </i>
      <span>{{ item.title }}</span>
    </p>
    <div class="section__flexible" ref="domSectionFlexible" :class="{ 'has-transition': hasTransition, 'is-collapsible': isCollapsible }" :style="style">
      <div class="flexible__inner" ref="domSectionFlexibleInner" v-if="item.body" v-html="item.body"></div>
      <div class="flexible__inner" ref="domSectionFlexibleInner" v-else>
        <div class="gallery">
          <figure 
            v-for="(image, idx) in item.pictures"
            :data-index="idx"
            :key="'sectionItemImage_' + idx"
            class="image"
            @click="onImageClick(image)"
          >
            <img :src="getImageSource(image)" :alt="item.headline" :title="item.headline" />
            <figcaption v-if="image.title">{{ image.title }}</figcaption>
          </figure>  
        </div>      
      </div>
    </div>
  </div>
</template>

<script>
//import Imageslider from "./Imageslider.vue";
import { ImageController } from "@/controller";

export default {
  name: "Sectionitem",
  components: {
    //Imageslider
  },
  computed: {
    isCollapsible() {
      return typeof this.item.collapsible == "boolean" && this.item.collapsible == false ? false : true;
    },
  },
  data() {
    return {
      height: 0,
      hasTransition: false,
      style: "",
      isActive: false
    }
  },
  watch: {
    item: function() {
      if (this.isCollapsible) {
        this.collapse();
      } 
    },
  },
  props: {
      item: {
        type: Object,
        default: null
      }
  },
  created() {
    if (!this.isCollapsible) this.style = "height: auto;";
  },
  methods: {
    handleClick(ev) {
      if (this.isCollapsible) {
        this.$emit("onClick", ev);
        if (this.isActive) {
          this.collapse();
        } else {
          this.expand();
        }
      }
    },
    collapse() {
      this.height = this.$refs.domSectionFlexibleInner.getBoundingClientRect().height;
      this.style = "height: " + this.height + "px;";
      console.log('collapse: ', this.style);
      this.hasTransition = true;
      this.$nextTick(() => {
        this.height = 0;
        this.style = "height: 0px;";
        this.isActive = false;
      });
    },
    expand() {
      this.height = 0
      this.style = "height: 0px;";
      this.hasTransition = true;
      this.isActive = true;
      this.$nextTick(() => {
        this.height = this.$refs.domSectionFlexibleInner.getBoundingClientRect().height;
        this.style = "height: " + this.height + "px;";
      });
    },
    onTransitionend() {
      this.hasTransition = false;
      //this.style = "";
    },
    getImageSource(image) {
      return ImageController.getItemImageSrc(image);
    },    
    onImageClick(image) {
      console.log('onImageClick', image);
      this.$lightbox({ image: image, gallery: this.item.pictures });
    },
  },
};
</script>

