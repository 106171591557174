<template>
  <div class="page page-energy-balance" ref="domEnergyBalance" >
    <h4 class="title">{{ title }}</h4>
    <div class="page-summary">
      <div 
        v-for="summaryEntry in page.summary"
        class="property"
        v-bind:key="summaryEntry.uniqeId"
      >
        <p class="property__label">{{ summaryEntry.title }} <Quickinfo v-if="summaryEntry.tooltip" :item="summaryEntry"/></p>
        <p class="property__value">{{ summaryEntry.value }}</p>
      </div>
    </div>
    <div class="page-chart page-chart-energy-balance">
      <div 
        class="chart"
        v-for="(plot,idx) in page.plots"
        v-bind:key="idx"
        :class="getChartClass(plot)"
      >
        <StackedBar v-if="plot.type == 'percent_vertical'" :plot="plot" />
        <Doughnut v-if="plot.type == 'percent_doughnut'" :plot="plot" />

      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import { StackedBar, Doughnut } from "@/components/";
import { Quickinfo } from "@/components/";

export default {
  name: "PageHeatBalance",
  components: {
    StackedBar,
    Doughnut,
    Quickinfo
  },
  computed: {
    ...mapGetters(["gSize"]),
    itemSubline() {
      if (this.detailSelected) {
        if ( Array.isArray(this.detailSelected.subline)) {
          return this.detailSelected.subline.join(" ");
        } else {
          return this.detailSelected.subline;
        }
      } else {
        return "";
      }
    },  
  },
  data() {
    return {
      selected: "",
      detailSelected: null,
      propertiesExpanded: false,
      hasPropertiesTransition: false,
      propertiesStyle: "",
      sizes: {
        half: "col-6"
      }
    }
  },
  watch: {
  },
  props: {
      mode: {
        type: String,
        default: ""
      },
      page: {
        type: Object,
        default: null
      },
      title: {
        type: String,
        default: ""
      }
  },
  created() {
    console.log('this.page', this.page);
    /* if (this.page  && this.page.summary.length && !this.detailSelected) {
      console.log('page', this.page);
      this.detailSelected = this.page.summary[0].detail;
    }  */
  },
  methods: {
    getChartClass(plot) {
      //console.log('plot', plot)
      return plot && plot.size ? this.sizes[plot.size] : "";
    },  
  }

};
</script>