<template>
  <div class="home">
    <div class="content-intro" :style="contentIntroStyle" v-if="!displayResult && !displayGdpr" v-show="isDesktop || showStartOnMobile">
      <header>
        <h1>{{ getContent('CONF_TEXT_HEADLINE', true) }}</h1>
      </header>
      <div class="image">
        <figure>
          <img v-if="isLandscape" src="@/assets/img/idm-intro-header_landscape.jpg" :alt="getContent('CONF_TEXT_HEADLINE', true)" :title="getContent('CONF_TEXT_HEADLINE', true)" />
          <img v-else src="@/assets/img/idm-intro-header.jpg" :alt="getContent('CONF_TEXT_HEADLINE', true)" :title="getContent('CONF_TEXT_HEADLINE', true)" />
        </figure>
      </div>
    </div>
    <div class="start-on-mobile" v-if="!displayResult && !displayGdpr" v-show="!isDesktop && showStartOnMobile">
      <button @click="handleMobileStartClick" class="button--primary hide-on-desktop">{{ getContent('TEXT_CONFIGURATE_NOW', true) }}</button>
    </div>
    <div class="start-service-links" v-if="!displayGdpr">
      <ul class="service-links">
        <li><a href="https://www.idm-energie.at/impressum/" target="_blank" class="link--service">{{ getContent('TEXT_IMPRINT', true) }}</a></li>
        <li v-if="false"><button class="button button--dense link--gdpr" @click="handleShowGdpr">{{ getContent('TEXT_DATA_PROTECTION', true) }}</button></li>
        <li><router-link to="/datenschutz">{{ getContent('TEXT_DATA_PROTECTION', true) }}</router-link></li>
      </ul>
    </div>
    <FormContainer :data-step="formStep" :class="{ 'is-active': !displayResult && !displayGdpr && (isDesktop || !showStartOnMobile) }" />
    <ResultContainer :class="{ 'is-active': displayResult && !displayGdpr }" />
    <Gdpr v-if="displayGdpr"/>
  </div>  
</template>

<script>

import { EventBus } from "@/bus/eventbus.js";
import { mapGetters } from "vuex";
import { FormContainer, ResultContainer, Gdpr } from "./components";
import { ConfiguratorFormController, ContentController } from "@/controller";

export default {
  name: "home",
  metaInfo: {
    title: "iDM Konfigurator"
  },
  components: {
    FormContainer,
    ResultContainer,
    Gdpr
  },
  data() {
    return {
      //formStep: 0,
      desktopSize: 1024,
      displayGdpr: false
    };
  },
  computed: {
    ...mapGetters(["gSize", "gUser"]),
    isLandscape() {
      return this.$store.state.app.size.screen.width>=this.$store.state.app.size.screen.height;
    },
    contentIntroStyle() {
      /* let left = "left: " + (this.gSize.screen.width>1440?(1440 / 2) + "px":"50%") + ";"; */
      return ""; //"width: auto; " + left;
    },
    displayResult() {
      return this.$store.state.app.displayResult;
    },
    isDesktop() {
      return this.$store.state.app.size.screen.width >= this.desktopSize;
    },
    showStartOnMobile() {
      if (this.$store.state.app.size.screen.width < this.desktopSize && !this.$store.state.app.startedOnMobile) {
        return true;
      } else if (this.$store.state.app.size.screen.width < this.desktopSize && this.$store.state.app.startedOnMobile) {
        return false;
      } else {
        return true;
      }
    },
    formStep() {
      return this.$store.state.app.activeFormStep;
    }
  },
  props: {},
  created() {
    //this.$store.dispatch("setDisplayResult", true);
    ConfiguratorFormController.checkConfigurationStartedOnMobile();
    EventBus.$on("onShowGdpr", () => {
      this.displayGdpr = true;
    });
    EventBus.$on("onHideGdpr", () => {
      this.displayGdpr = false;
    });     
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    handleMobileStartClick() {
      ConfiguratorFormController.configurationStartedOnMobile();
    },
    handleShowGdpr() {
      EventBus.$emit("onShowGdpr");
    },
    getContent(content, clean) {
      return ContentController.getContent(content, clean);
    }
  }
};
</script>