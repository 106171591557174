<template>
  <div class="form-property form-property--select" :class="{ 'is-disabled': disabled }">
    <label :for="'select'+item.name">{{ item.label }} <Quickinfo v-if="item.quickinfo" :item="item"/></label>
    <input type="hidden" v-model="value" />
    <div class="customselect" :class="{ 'is-disabled': disabled}">
      <p class="selected" @click.prevent="handleSelectClick" :class="{ 'is-active': listActive }">
        {{ selectedValue.text }}
          <i class="svgicon svgicon--chevrondown" :class="{ 'is-active': listActive }">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.41 8.59L12 13.17L16.59 8.59L18 10L12 16L6 10L7.41 8.59Z" fill="white"/>
            </svg>
          </i>
      </p>
      <ul class="selectlist" :class="{ 'is-active': listActive }" @click="handleSelectListClick">
        <li
          v-for="(option, index) in item.options"
          :key="'optionItem' + item.name + index"
          :data-value="option.value"
          :class="{ 'is-hidden': !option.show }"
        >
          {{ option.text }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Quickinfo } from "@/components/";

export default {
  name: "SelectField",
  components: {
    Quickinfo
  },  
  computed: {
  },
  props: {
      handles: {
        type: Array,
        default: null
      },
      item: {
        type: Object,
        default: null
      },
      storedValue: {
        type: Object,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      }
  },
  data() {
    return {
      value: 0,
      selectedValue: {
        text: "",
        value: 0
      },
      listActive: false
    }
  },
  watch: {
    storedValue(val) {
      this.value = val.value;
      this.selectedValue.value = this.value;
      const selected = (this.item && this.item.options ? this.item.options.filter(o => o.value == this.value): []);
      this.selectedValue.text = (selected && selected.length && selected[0].text) || "";
    }
  },
  created() {
    this.value = this.storedValue.value; 
  },
  mounted() {
    this.selectedValue.value = this.value;
    const selected = (this.item && this.item.options ? this.item.options.filter(o => o.value == this.value): []);
    this.selectedValue.text = (selected && selected.length && selected[0].text) || "";
  },
  methods: {
    handleSelectClick() {
      this.listActive = !this.listActive;
    },
    handleSelectListClick(ev) {
      if (ev.target.nodeName == "LI") {
        this.selectedValue.value = this.value = ev.target.dataset['value'];
        this.selectedValue.text = ev.target.innerHTML.trim();
        this.listActive = false;
        this.$emit("onChange", ev, this.item, this.value);
      }
    }
  },
};
</script>