<template>
  <p class="cardtag">{{ tag }}</p>
</template>

<script>

export default {
  name: "Card",
  components: {
  },
  computed: {
  },
  data() {
    return {}
  },
  watch: {
  },
  props: {
      tag: {
        type: String,
        default: ""
      }
  },
  created() {
  },
  methods: {
  },
};
</script>
