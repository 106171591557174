<template>
    <div class="help-content-part help-content-part--filter">
      <p class="help-title">{{ getContent('TEXT_TERMS_SYSTEM', true) }}</p>
      <Helpitem       
        v-for="(help, idx) in systemhelp"
        :key="'filterHelpItem' + idx"
        :item="help"
        :title="help.title"
        :icon="help.icon"
        :text="help.desc"
        type="system"
      >
      </Helpitem>    
    </div>
</template>

<script>
import Helpitem from "./Helpitem.vue";
import { ContentController } from "@/controller";

export default {
  name: "HelpSystem",
  components: {
    Helpitem
  },
  computed: {
    /* helpItems() {
      console.log("HelpSystem:helpItems:this.systemhelp", this.systemhelp);
      return this.systemhelp;
    } */
  },
  created() {
  },
  data() {
    return {
      //helpItems: []
    }
  },
  props: {
    systemhelp: {
      type: Array,
      default: null
    }
  },  
  watch: {
    /* systemhelp: function() {
      console.log("HelpSystem:helpItems:this.systemhelp", this.systemhelp);
      this.helpItems = Object.assign({}, this.systemhelp);
    }, */
  },
  methods: {
    collapseProperties() {
      this.propertiesStyle = "height: " + this.$refs.domPropertiesList.getBoundingClientRect().height + "px;";
      this.hasPropertiesTransition = true;
      this.$nextTick(() => {
        this.propertiesStyle = "height: " + this.getPropertiesCollapsedHeight() + "px;";
        this.propertiesExpanded = false;
      });
    },
    expandProperties() {
      this.style = "height: " + this.getPropertiesCollapsedHeight() + "px;";
      this.hasPropertiesTransition = true;
      this.propertiesExpanded = true;
      this.$nextTick(() => {
        this.propertiesStyle = "height: " + this.$refs.domPropertiesList.getBoundingClientRect().height + "px;";
      });
    },    
    getContent(content, clean) {
      return ContentController.getContent(content, clean);
    }          
  }
};
</script>
