<template>
  <div class="page page-performance-data" ref="domClimateData" >
    <h4 class="title">{{ title }}</h4>
    <div class="page-summary">
      <div 
        v-for="summaryEntry in page.summary"
        class="property"
        v-bind:key="summaryEntry.uniqeId"
      >
        <p class="property__label">{{ summaryEntry.title }} <Quickinfo v-if="summaryEntry.tooltip" :item="summaryEntry"/></p>
        <p class="property__value">{{ summaryEntry.value }}</p>
      </div>
    </div>
    <div class="page-chart page-chart-performance-data">
      <div 
        class="chart"
        v-for="(plot,idx) in page.plots"
        v-bind:key="idx"
        ref="domChart"
      >
        <h4 class="chart-title" ref="domChartTitle">{{ plot.title  }}</h4>
        <PlaneDiagram v-if="true || plot.type == 'bar'" :plot="plot" :chartWidth="chartWidth" :chartHeight="chartHeight" />
      </div>
    </div>
  </div>
</template>

<script>
import Store from "@/store";
import { mapGetters } from "vuex";
import { PlaneDiagram } from "@/components/";
import { Quickinfo } from "@/components/";
import { EventBus } from "@/bus/eventbus.js";

export default {
  name: "PagePerformanceData",
  components: {
    PlaneDiagram,
    Quickinfo
  },
  computed: {
    ...mapGetters(["gSize"]),
    itemSubline() {
      if (this.detailSelected) {
        if ( Array.isArray(this.detailSelected.subline)) {
          return this.detailSelected.subline.join(" ");
        } else {
          return this.detailSelected.subline;
        }
      } else {
        return "";
      }
    }
  },
  data() {
    return {
      selected: "",
      detailSelected: null,
      propertiesExpanded: false,
      hasPropertiesTransition: false,
      propertiesStyle: "",
      chartHeight: 0,
      chartWidth: 0,      
    }
  },
  watch: {
  },
  props: {
      mode: {
        type: String,
        default: ""
      },
      page: {
        type: Object,
        default: null
      },
      title: {
        type: String,
        default: ""
      }
  },
  created() {
    console.log('this.page', this.page);
    EventBus.$on("doResize", () => {
      this.getChartHeight();
      this.getChartWidth();
    });
  },
  mounted() {
    this.getChartHeight();
    this.getChartWidth();
  },
  methods: {
    getChartHeight() {
      const headlineHeight = (this.$refs.domChartTitle.length ? this.$refs.domChartTitle[0].getBoundingClientRect().height:76);
      console.log("PerformanceData:getChartHeight:headlineHeight", headlineHeight);
      this.chartHeight = (this.$refs.domChart.length ? this.$refs.domChart[0].getBoundingClientRect().height:0) - (Store.state.app.size.screen.width < 1024 ? 24 : 54) - headlineHeight;
    },
    getChartWidth() {
      this.chartWidth = (this.$refs.domChart.length ? this.$refs.domChart[0].getBoundingClientRect().width:0) - (Store.state.app.size.screen.width < 1024 ? 24 : 109);
    }
  }  
};
</script>