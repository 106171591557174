<template>
  <div class="form-property" :class="{ 'is-disabled': disabled }">
    <p class="form-property__label">{{ item.label }} <Quickinfo v-if="item.quickinfo" :item="item"/></p>
    <div class="bool-group">
      <input 
        type="checkbox"
        :name="'checkbox' + item.name"
        :id="'checkbox' + item.name"
        v-model="value"
        :class="{ 'is-selected': value, 'is-disabled': disabled }"
      />
      <div class="button-group">
        <button class="button--bool" :name="'buttonBoolTrue' + item.name" :id="'buttonBoolTrue' + item.name" :class="{ 'is-selected': value }" @click.prevent="onChange($event, item, true)">{{ getContent('TEXT_YES', true) }}</button>
        <button class="button--bool" :name="'buttonBoolFalse' + item.name" :id="'buttonBoolFalse' + item.name" :class="{ 'is-selected': !value }" @click.prevent="onChange($event, item, false)">{{ getContent('TEXT_NO', true) }}</button>
      </div>
    </div>
  </div>
</template>

<script>

import { Quickinfo } from "@/components/";
import { ContentController } from "@/controller";

export default {
  name: "BoolButton",
  components: {
    Quickinfo
  },
  computed: {
    direction() {
      let str = this.item.type.substr(this.item.type.indexOf('_')+1);
      return str;
    },
  },
  props: {
      handles: {
        type: Array,
        default: null
      },
      item: {
        type: Object,
        default: null
      },
      storedValue: {
        type: Object,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      }
  },
  data() {
    return {
      value: this.storedValue.value,
    }
  },
  watch: {
    storedValue: {
      handler: function() {
        this.value = this.storedValue.value;
      }
    }
  },  
  created() {
  },
  methods: {
    onChange(ev, item, value) {
      if (value != this.value) {
        this.value = value;
        this.$emit("onChange", ev, item, this.value);
      }
    },
    getContent(content, clean) {
      return ContentController.getContent(content, clean);
    }        
    /* getOptionsToShow(item) {
      return ConfiguratorFormController.getOptionsToShow(item);
    }, */
  },
};
</script>