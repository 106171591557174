<template>
  <div @click="handleClick(item)" :class="{ 'has-linked': item.subcards, 'is-linked': parent }" >
    <div class="detaillistitem__content" ref="domCardContent">
        <header>
          <h3 class="headline" ref="domItemHeadline">{{ (item?item.headline:'') }}</h3>
          <h4 class="subline">{{ itemSubline }}</h4>
          <div class="taglist" v-if="item && item.tags">
            <Cardtag 
              v-for="(tag, idx) in item.tags"
              :key="item.uniqeId + '_' + idx"
              :tag="tag"
            /> 
          </div>
        </header>
        <div class="icon" v-if="item && item.icon">
          <Cardicon :icon="item.icon" />
        </div>
    </div>
  </div>
</template>

<script>

import Cardtag from "../list/Cardtag.vue";
import Cardicon from "../list/Cardicon.vue";

export default {
  name: "Listitem",
  components: {
    Cardtag,
    Cardicon
  },
  computed: {
    itemSubline() {
      if (this.item) {
        if ( Array.isArray(this.item.subline)) {
          return this.item.subline.join(" ");
        } else {
          return this.item.subline;
        }
      } else {
        return "";
      }
    },
  },
  data() {
    return {
      items: [],
      heights: {
        card: -1,
        flexibleInner: -1
      },
      cardTransition: false
    }
  },
  watch: {
    collapsed() {
      if (this.collapsed) this.onCollapse();
    },
    expanded() {
      if (this.expanded) this.onExpand();
    }
  },
  props: {
      mode: {
        type: String,
        default: ""
      },
      item: {
        type: Object,
        default: null
      },
      parent: {
        type: Object,
        default: null
      }
  },
  created() {

  },
  methods: {
    handleClick(item) {
      this.$emit("onClick", item);
    },
    onCollapse() {
      this.heights.card = this.$refs.domCardContent.getBoundingClientRect().height;
      this.heights.flexibleInner = this.getFlexibleHeight();
      this.cardTransition = true;
      this.$nextTick(() => {
        this.heights.flexibleInner = 0;
        this.heights.card = this.getHeaderMinHeight();
      });
      
    },
    onExpand() {
      this.heights.card = this.getHeaderMinHeight();
      this.cardTransition = true;
      this.$nextTick(() => {
        this.heights.flexibleInner = this.getFlexibleHeight();
        this.heights.card = this.getHeaderHeight() + (!this.heights.flexibleInner?this.getCardPaddingHeight():this.heights.flexibleInner + this.getCardPaddingHeight());
      });
    },
    onCardTransitionEnd() {
      this.cardTransition = false;
    },
    onFlexTransitionEnd() {
      this.flexTransition = false;
    },
    getHeaderHeight() {
      return this.$refs.domItemHeader?this.$refs.domItemHeader.getBoundingClientRect().height:0;
    },
    getHeaderMinHeight() {
        let h = (this.$refs.domItemHeadline?this.$refs.domItemHeadline.getBoundingClientRect().height:0) + this.getCardPaddingHeight();
        return h;
    },
    getContentMinHeight() {
      let h = this.$refs.domCardContent?this.$refs.domCardContent.getBoundingClientRect().height:-1 + this.getCardPaddingHeight();
      return h;
    },
    getFlexibleHeight() {
      return this.$refs.domFlexibleInner?this.$refs.domFlexibleInner.getBoundingClientRect().height:0;
    },
    getCardPaddingHeight() {
      if (this.$refs.domCard) {
        const cStyle = window.getComputedStyle(this.$refs.domCard);
        return (cStyle["padding-top"]?parseInt(cStyle["padding-top"]):0) + (cStyle["padding-bottom"]?parseInt(cStyle["padding-bottom"]):0);
      } else {
        return 0;
      }
    }
  },
};
</script>
