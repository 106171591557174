const SmoothScrollTo = {
  animateTime: 500,
  speed: 500,
  movingFrequency: 15,
  hopCount: 0,
  getScrollTopDocumentAtBegin: 0,
  gap: 0,
  temp: 0,

  scroll(el, addToTop)
  {
    if (el) {
      this.hopCount = Math.ceil(this.speed/this.movingFrequency);
      this.getScrollTopDocumentAtBegin = this.getPageScroll();
      this.gap = (this.getScrollTopElement(el) - (addToTop?addToTop:0) - this.getScrollTopDocumentAtBegin) / this.hopCount;
      this.temp = this.hopCount;
      window.requestAnimationFrame(this.step);
    }            
  },

  getScrollTopElement(e)
  {
      let top = 0;

      while (e.offsetParent != undefined && e.offsetParent != null)
      {
          top += e.offsetTop + (e.clientTop != null ? e.clientTop : 0);
          e = e.offsetParent;
      }

      return top;
  },

  getPageScroll()
  {
    var yScroll;
    if (window.pageYOffset) {
      yScroll = window.pageYOffset;
    } else if (document.documentElement && document.documentElement.scrollTop) {
      yScroll = document.documentElement.scrollTop;
    } else if (document.body) {
      yScroll = document.body.scrollTop;
    }
    return yScroll;
  },

  step()
  {
    SmoothScrollTo.hopTopPosition = SmoothScrollTo.gap * (SmoothScrollTo.temp - SmoothScrollTo.hopCount);
    window.scrollTo(0, SmoothScrollTo.hopTopPosition + SmoothScrollTo.getScrollTopDocumentAtBegin);
    SmoothScrollTo.hopCount--;
    if(SmoothScrollTo.hopCount < 0)
      return;
    else
      window.requestAnimationFrame(SmoothScrollTo.step);
  }        
}

export default SmoothScrollTo;