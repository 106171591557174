<template>
  <div class="form-property" :class="{ 'is-disabled': disabled }">
    <h4 :class="{ 'is-disabled': disabled }">{{ item.label }} <Quickinfo v-if="item.quickinfo" :item="item"/></h4>
  </div>
</template>

<script>
import { Quickinfo } from "@/components/";

export default {
  name: "Heading",
  components: {
    Quickinfo
  },  
  computed: {
  },
  props: {
      item: {
          type: Object,
          default: null
      },
      disabled: {
        type: Boolean,
        default: false
      }
  },
  data() {
    return {
      value: "",
    }
  },
  created() {
  },
  methods: {
  },
};
</script>