<template>
  <div class="service service--gpdr gdpr">
    <div class="content">
      <header>
        <h3>Datenschutzinformation</h3>
      </header>
      <p>Mit Ihrem Zugriff auf unsere Webseiten werden Informationen über den Zugriff (Datum, Uhrzeit, betrachtete Seite, IP-Adresse) als Protokolldateien auf unserem Server gespeichert. Wir tun das aus dem berechtigten Interesse der Überwachung des technischen Betriebs, zur Optimierung unseres Informationsangebots und zur Erkennung von Angriffen auf unsere Website (Art. 6 (1) f DSGVO). Diese Daten werden von uns ausschließlich für eigene, statistische Zwecke, in anonymisierter Form ausgewertet.</p>
      <p>Die Protokolldateien werden nach drei Monaten gelöscht.</p>
      <p><a href="https://www.idm-energie.at/impressum/" target="blank">Verantwortlich für die Verarbeitung der personenbezogenen Daten ist die iDM Energiesysteme GmbH („iDM“)</a>.</p>
      <p>Für Fragen zum Datenschutz bei iDM kontaktieren Sie bitte unseren Datenschutzbeauftragten unter <a href="mailto:datenschutz@idm-energie.at">datenschutz@idm-energie.at</a>.</p>
      <p>Sofern es in dieser Datenschutzinformation nicht anders erwähnt wird, werden Ihre Daten grundsätzlich nicht an Dritte weitergegeben. Eine Ausnahme ist die Weitergabe an Auftragsverarbeiter (technische Dienstleister), die ausschließlich auf Weisung von iDM arbeiten, die Daten nicht für eigene Zwecke verwenden und über eigene Vereinbarungen an die datenschutzrechtlichen Verpflichtungen der Datenschutzgrundverordnung gebunden sind.</p>
      <p>Sie haben das Recht auf Auskunft, Berichtigung und Löschung der Daten, das Recht auf Einschränkung der Verarbeitung, auf Widerspruch zur Verarbeitung (sofern die Verarbeitung auf einem berechtigten Interesse beruht) sowie das Recht zur Beschwerde bei einer Aufsichtsbehörde.</p>
      <h4>Datenweitergabe an Heizungsbauer</h4>
      <p>Um die für Ihre Anforderungen optimale iDM Wärmepumpen-Konfiguration zu berechnen, werden Angaben zu Ihrer Wohnsituation und dem Gebäude mit Klimainformationen an dem von Ihnen angegebenen Standort (Postleitzahl) verknüpft. Daraus entsteht ein Systemvorschlag, der von einem unserer Vertriebspartner bzw. Heizungsbauer geprüft werden kann, sodass dieser Ihnen ein maßgeschneidertes Angebot für die Umsetzung erstellen kann. Sie können daher den Systemvorschlag direkt an einen in Ihrer Region arbeitenden iDM-Vertriebspartner senden. Dabei werden auch Ihre personenbezogenen Daten weitergegeben, damit der Vertriebspartner mit Ihnen Kontakt aufnehmen kann. Die Rechtsgrundlage für diese Datenweitergabe ist Art. 6 (1) b DSGVO (Durchführung vorvertraglicher Maßnahmen, die auf Anfrage der betroffenen Person erfolgen). Aus dem berechtigten Interesse der Gewährleistungen einer optimalen Servicequalität (Art. 6 (1) f DSGVO) überprüft iDM auch per E-Mail, ob die Kommunikation mit dem Vertriebspartner korrekt und zufriedenstellend verlaufen ist. Dazu werden die Daten für drei Jahre aufbewahrt.</p>
      <h4>Optimierung von Produkten und Dienstleistungen</h4>
      <p>In anonymisierter Form werden Daten zur Verbesserung der iDM-Produkte und -Dienstleistungen verwendet, beispielsweise um in einzelnen Regionen besonders gefragte Produktkombinationen zu identifizieren. Zusätzlich erfolgt aus dem berechtigten Interesse der Produkt- und Marketingplanung (Art. 6 (1) f DSGVO) auch eine Verknüpfung zwischen den im Konfigurator eingegebenen Daten und installierten Neuanlagen. Dazu werden die Daten für drei Jahre aufbewahrt.</p>
    </div>
  </div>  
</template>

<script>

import { ContentController } from "@/controller";

export default {
  name: "Gdpr",
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  props: {},
  created() {
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    getContent(content) {
      return ContentController.getContent(content);
    }    
  }
};
</script>