<template>
  <ul class="card-dots">
    <li 
      v-for="item in dots"
      :key="itemId + '_' + item"
      :class="{ 'is-set': item > (dots.length - (dotValue+1)) }"
    >
      <span></span>
    </li>
  </ul>
</template>

<script>

import { ImageController } from "@/controller";

export default {
  name: "Carddots",
  components: {
  },
  computed: {
    imageSource() {
      return ImageController.getCardImageSrc(this.src);
    }
  },
  data() {
    return {
      dots: [...Array(10).keys()]
    }
  },
  watch: {
  },
  props: {
      dotValue: {
        type: Number,
        default: 0
      },
      itemId: {
        type: String,
        default: ""
      }
  },
  created() {
  },
  methods: {
  },
};
</script>
