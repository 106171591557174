<template>
  <div class="page page-energy-balance" ref="domEnergyBalance" >
    <h4 class="title">{{ title }}</h4>
    <div class="page-summary">
      <div 
        v-for="summaryEntry in page.summary"
        class="property"
        :class="{ 'form-property': summaryEntry.type=='input' }"
        v-bind:key="summaryEntry.uniqeId"
      >
        <p v-if="summaryEntry.type != 'input'" class="property__label">{{ summaryEntry.title }} <Quickinfo v-if="summaryEntry.tooltip" :item="summaryEntry"/></p>
        <p v-if="summaryEntry.type != 'input'" class="property__value"><span v-if="summaryEntry.type == 'calculation_result'" class="property__calculation-value">{{ calculation.values[summaryEntry.uniqeId] }}</span> {{ summaryEntry.value }}</p>
        <label v-if="summaryEntry.type == 'input'" class="property__label" :for="'input' + summaryEntry.variable">{{ summaryEntry.title }}</label>
        <input 
          v-if="summaryEntry.type == 'input'"
          :name="'input' + summaryEntry.variable" 
          :id="'input' + summaryEntry.variable" 
          type="number" 
          class="property__input" 
          :data-variable="summaryEntry.variable" 
          :value="calculation.variables[summaryEntry.variable] ? calculation.variables[summaryEntry.variable].value : '0'"
          @keyup="onKeyup($event, summaryEntry.variable)" 
          @change="onChange($event, summaryEntry.variable)" />
          <p v-if="summaryEntry.type == 'input'" class="property__value">{{ summaryEntry.value }}</p>
      </div>
    </div>
    <div class="page-chart page-chart-energy-balance">
      <div 
        class="chart"
        v-for="(plot,idx) in page.plots"
        v-bind:key="idx"
        :class="getChartClass(plot)"
      >
        <StackedBar v-if="plot.type == 'percent_vertical'" :plot="plot" />
        <Doughnut v-if="plot.type == 'percent_doughnut'" :plot="plot" />
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import { StackedBar, Doughnut } from "@/components/";
import Calculate from "@/utils/Calculate.js";
import { Quickinfo } from "@/components/";

export default {
  name: "PageEnergyBalance",
  components: {
    StackedBar,
    Doughnut,
    Quickinfo
  },
  computed: {
    ...mapGetters(["gSize"]),
    itemSubline() {
      if (this.detailSelected) {
        if ( Array.isArray(this.detailSelected.subline)) {
          return this.detailSelected.subline.join(" ");
        } else {
          return this.detailSelected.subline;
        }
      } else {
        return "";
      }
    },  
  },
  data() {
    return {
      selected: "",
      detailSelected: null,
      propertiesExpanded: false,
      hasPropertiesTransition: false,
      propertiesStyle: "",
      sizes: {
        half: "col-6"
      },
      calculation: {
        variables: {},
        formulas: {},
        values: {}
      }
    }
  },
  watch: {
    calculation: function(val) {
      console.log('watch calculation', val);
    }
  },
  props: {
      mode: {
        type: String,
        default: ""
      },
      page: {
        type: Object,
        default: null
      },
      title: {
        type: String,
        default: ""
      }
  },
  created() {
    //console.log('this.page', this.page);
    /* if (this.page  && this.page.summary.length && !this.detailSelected) {
      console.log('page', this.page);
      this.detailSelected = this.page.summary[0].detail;
    }  */
  },
  mounted() {
    this.getVariablesFormulasFromSummary();
  },
  methods: {
    getChartClass(plot) {
      //console.log('plot', plot)
      return plot && plot.size ? this.sizes[plot.size] : "";
    },  
    getVariablesFormulasFromSummary() {
      this.page.summary.forEach(entry => {
        if (entry.variable) {
          this.$set(this.calculation.variables, entry.variable, {
            default: entry.default,
            value: entry.default,
            entry: entry
          });
          entry.calculated = entry.default;
        } else if (entry.formula) {
          this.$set(this.calculation.formulas, entry.uniqeId, entry.formula);
          this.$set(this.calculation.values, entry.uniqeId, entry.default);
          entry.calculated = entry.default;
        }
      });
      console.log('getVariablesFromSummary()', this.calculation);
    },
    getValue(vName) {
      //const entry = this.calculation.variables.filter(v => v.name == vName);
      //console.log('getValue', vName, entry);
      return ( this.calculation.variables[vName] ? ( this.calculation.variables[vName].value ? this.calculation.variables[vName].value : this.calculation.variables[vName].default ) : 0 );
    },
    onKeyup(ev, vName) {
      //console.log('onKeyup', vName);
      this.setVariablesAndValues(vName, ev.target.value);
    },
    onChange(ev, vName) {
      this.setVariablesAndValues(vName, ev.target.value);
      //console.log('onChange', vName, this.calculation);
    },
    setVariablesAndValues(vName, vValue) {
      //const tempCalc = this.calculation;
      this.calculation.variables[vName].value = vValue;
      for (let propName in this.calculation.formulas) {
        if (this.calculation.formulas[propName].indexOf(vName) != -1) {
          let value;
          if (vValue) {
            let exp = this.calculation.formulas[propName];
            //this.$set(this.calculation.values, entry.uniqeId, entry.default);
             value = Calculate.calc(exp.replace(vName, vValue)).toFixed(1).replace('.', ',');
          } else {
            value = "";
          }
          if (this.calculation.values[propName] != value)  {
            this.calculation.variables[vName].entry.calculated = vValue;
            this.$set(this.calculation.values, propName, value);
              //set the entry calculated for later use
            const entry = this.page.summary.find(entry => entry.uniqeId == propName);
            if (entry) entry.calculated = value;
            this.$emit("onCalculationChanged");
          }
          //this.$set(this.calculation.values)
        }
      }
      //this.$set(this.calculation, tempCalc);
      //this.calculation = this.calculation;
      //this.calculation = Object.assign({}, this.calculation);
      //console.log("EnergyBalance:setVariablesAndValues:this.calucation", this.calculation);
    }
  }

};
</script>