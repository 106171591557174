<template>
  <div class="configurator-detail" :class="{ 'is-expert': mode=='expert' }">
    <Detail :item="item" :mode="mode" />
    <DetailFooter :item="item" :mode="mode" :projectPdf="projectPdf"/>
  </div>
</template>

<script>

import { ConfiguratorResultController, PdfController, RequestController } from "@/controller";
import { Detail, DetailFooter } from "./detail";

export default {
  name: "ConfiguratorDetail",
  components: {
    Detail,
    DetailFooter
  },
  computed: {
  },
  data() {
    return {
      formData: [],
      formValues: [],
      country: "AT",
      placesSearch: "",
      items: [],
      expanded: [],
      usersChoice: 1,
      projectPdf: {
        data: "",
        filename: ""
      }
    }
  },
  watch: {
    mode() {
      //console.log('this.mode', this.mode);
      this.reset();
    },
    filters() {
      //console.log("watch filter", this.filters);
    },
    item() {
      console.log("ConfiguratorDetail:watch:item", this.item);
      PdfController.resetPdf();
    }
  },
  props: {
      mode: {
          type: String,
          default: ""
      },
      item: {
        type: Object,
        default: null
      }
  },
  created() {
  },
  methods: {
    /* async getResultData() {
      this.items = await ConfiguratorResultController.getData();
      console.log("ConfiguratorResult[getResultData] this.items", this.items);
      this.buildExpandedEntries();
    }, */
    buildExpandedEntries() {
      this.items.forEach(() => this.expanded.push({
        index: -1,
        linkedIndex: -1
      }));
    },
    setExpanded(cardidx, idx, subidx) {
      if (this.expanded[cardidx]) this.expanded[cardidx].index = idx;
      if (this.expanded[cardidx]) this.expanded[cardidx].linkedIndex = subidx;
    },
    getCardExpanded(cardidx, idx) {
      return this.expanded[cardidx] && (this.expanded[cardidx].index==idx && this.expanded[cardidx].linkedIndex==-1);
    },
    getCardCollapsed(cardidx, idx) {
      return this.expanded[cardidx] && ((this.expanded[cardidx].index!=-1 && this.expanded[cardidx].index!=idx) || this.expanded[cardidx].linkedIndex != -1);
    },
    getLinkedCardExpanded(cardidx, idx, subidx) {
      return this.expanded[cardidx] && (this.expanded[cardidx].index==idx && this.expanded[cardidx].linkedIndex==subidx);
    },
    getLinkdedCardCollapsed(cardidx, idx, subidx) {
      return this.expanded[cardidx] && ((this.expanded[cardidx].index != -1 && this.expanded[cardidx].linkedIndex == -1) || (this.expanded[cardidx].linkedIndex != -1 && this.expanded[cardidx].linkedIndex != subidx));
    },
    filterChanged() {
      this.items = ConfiguratorResultController.getFilteredItems();
      console.log('ConfiguratorResult[filterChanged] this.items', this.items);
    },
    reset() {
      console.log("ConfiguratorResult[reset]", this.mode);
    },
    handlePdfGenerated() {
      this.projectPdf = {
        data: RequestController.pdf,
        filename: RequestController.pdfFilename
      }
    }
  },
};
</script>
